textarea,
select,
input,
.qty {
  padding: 12px 12px;
  border-radius: 0 !important;
  border: 1px solid $color-text-titles;
  font-size: 16px;
  color: $color-text-titles;
  background: transparent !important;
  background-size: 15px auto !important;
  background-position: right 15px top 20px !important;
  @media (max-width: 575px) {
    font-size: $font-size-smaller;
    padding: 11px 11px;
  }
  &::placeholder {
    color: $color-grey-2;
    opacity: 1;
  }
}

.qty {
  text-align: center;
  width: 80px;
}

.shop_table .qty {
  width: 70px;
}

select {
  background: transparent url("../assets/ico/ico-chevron-down-dark.svg")
    no-repeat right center !important;
  background-size: 15px auto !important;
  border: 1px solid $color-grey-3;
  background-position: right 15px top 20px !important;
  cursor: pointer;
  /* Hide default dropdown arrow */
  appearance: none;
  transition: $trans;
  padding-right: 2rem !important;
  font-size: $font-size;

  &.orderby {
    padding: 8px 10px;
    // background-position: right 10px top 7px;
    box-shadow: none;
  }

  &:hover {
    color: $color-secondary;
    transition: $trans;
  }
}

.selection {
  .select2-selection {
    height: 50px !important;
    border: 1px solid $color-grey-3 !important;
    box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
    background-color: transparent !important;
    color: $color-grey-2 !important;
  }

  .select2-selection__rendered {
    padding: 10px 14px;
    color: $color-grey-2;
  }

  .select2-selection__arrow {
    padding: 10px 12px;
    margin-top: 12px;
    margin-right: 5px;
  }

  .select2-selection__clear {
    margin-right: 1rem;
  }

  .select2-selection__arrow b {
    border-width: 10px 10px 0 10px;
  }
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: $color-grey-2 !important;
}

.select2-container {
  .select2-dropdown {
    background-color: transparent;
    color: $color-grey-2;
    border-color: $color-grey-3 !important;
  }
}

.select2-container--default .select2-results__option[aria-selected="true"],
.select2-container--default .select2-results__option[data-selected="true"] {
  background-color: $color-background-offCanvas !important;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected],
.select2-container--default
  .select2-results__option--highlighted[data-selected] {
  background-color: $color-background-offCanvas !important;
}

.qib-container {
  .minus {
    border-radius: 4px 0 0 4px !important;
  }
  .plus {
    border-radius: 0 4px 4px 0 !important;
  }
}

.page-title {
  padding: $space-6 0 $space-3 0;
  @media screen and (max-width: 767px) {
    padding-top: $space-5;
  }
  @media screen and (max-width: 575px) {
    padding-top: $space-4 + $space-01;
    padding-bottom: $space-2;
  }

  &--product{
    padding: $space-5 0 $space-3 0;
    @media screen and (max-width: 767px) {
      padding: $space-4 0 $space-2 0;
    }
    @media screen and (max-width: 575px) {
      padding-top: $space-3 0 $space-1 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $typo-secondary;
    color: $color-text-titles;
    text-align: center;
    max-width: 75%;
  }

  &__title-cont {
    overflow: hidden;
  }

  &__title {
    font-size: $display-1;
    opacity: 0;
    transform: translateY(230px);
    text-transform: uppercase;
    padding-top: $font-size;
    @media screen and (max-width: 991px) {
      font-size: $display-2;
    }
    @media screen and (max-width: 767px) {
      font-size: $display-3;
    }
    @media screen and (max-width: 480px) {
      font-size: $header-1-res;
    }
    &--sm {
      font-size: $display-2;
      @media screen and (max-width: 991px) {
        font-size: $display-3;
      }
      @media screen and (max-width: 767px) {
        font-size: $display-4;
      }
    }
  }
  &__subtitle {
    text-align: center;
    color: $color-text-body;
    font-size: $header-3;
    margin-bottom: $font-size;
    text-transform: uppercase;
    @media screen and (max-width: 575px) {
      font-size: $font-size-bigger;
    }
    @media screen and (max-width: 480px) {
      font-size: $font-size-big;
    }
  }
  &__breadcrumbs {
    margin-bottom: $font-size / 2;
    font-size: $font-size-small;
    color: $color-text-body;
    @media screen and (max-width: 575px) {
      margin-bottom: 0px;
      font-size: $font-size-smaller;
    }
    a {
      font-weight: 500;
      color: $color-text-titles;
      text-decoration: none;
      font-weight: $font-weight-bold;
    }
    @media screen and (min-width: 992px) {
      > span {
        > span {
          > a {
            margin-right: 0.3rem;
          }
          > span {
            a {
              margin-left: 0.3rem;
              margin-right: 0.3rem;
            }
          }
        }
      }
      .breadcrumb_last {
        margin-left: 0.3rem;
      }
    }
  }
}

// .single-programas {
//   .page-title {
//     padding: $space-2 0;
//   }
// }

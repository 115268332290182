// .hero {
//   padding: $space-6 0 $space-5 0;
//   &__content {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     font-family: $typo-secondary;
//     text-transform: uppercase;
//     color: $color-text-titles;
//   }

//   &__text-container {
//     overflow: hidden;
//   }

//   &__title {
//     font-size: 96px;
//     opacity: 0;
//     transform: translateY(150px);
//   }
//   &__subtitle {
//     font-size: $header-3;
//   }
// }

.hero-v {
  // background: white;
  margin-top: $space-4;
  height: calc(100vh - 7rem);
  margin-right: $space-02;
  margin-bottom: $space-02;
  margin-left: $space-02;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 575px) {
    margin-top: $space-3;
    height: calc(100vh - 5rem);
    margin-right: $space-01;
    margin-bottom: $space-01;
    margin-left: $space-01;
  }

  &__video {
    position: relative;
    z-index: 1;
    object-fit: cover;

    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__overlay {
    // z-index: 2;
    // width: 100%;
    // height: 100%;
    // position: absolute;
    // background-color: $color-dark;
    // opacity: 0.2;
  }
}

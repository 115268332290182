@mixin woofpaddings {
  display: block;
  margin-bottom: 0.4rem !important;
  padding-bottom: 0.4rem !important;
  border-bottom: 1px dashed rgba($color: #000000, $alpha: 0.1);
}

.woof_list_checkbox {
  padding-left: 0;
  > .woof_childs_list_li,
  > li > li,
  > li {
    @include woofpaddings;
  }

  .woof_childs_list {
    padding-left: 0.5rem;
  }

  li .woof_checkbox_label {
    padding-top: 3px;
    padding-bottom: 3px;
    display: inline-block;
    width: 70%;
    font-family: $typo-secondary;
  }

  .woof_checkbox_count {
    font-size: 80% !important;
  }

  .woof_childs_list {
    align-self: flex-end;
  }
}

.woof_container {
  margin-bottom: 2rem !important;
  .woof_container_inner {
    > h4 {
      margin-bottom: 1rem;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
}

.archive .woof_list_checkbox > li {
  @include woofpaddings;
}

.woof_products_top_panel {
  line-height: initial !important;

  .woof_products_top_panel_ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
    li {
      ul {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        li {
          a {
            background-color: $color-grey-5;
            padding: 3px 10px;
            border-radius: 50px;
            border: 1px solid rgba($color: #000000, $alpha: 0.1) !important;
            display: inline-flex;
          }
        }
      }
    }
  }

  .woof_reset_button_2 {
    padding: 5px 1rem !important;
    border-radius: 3px;
    background-color: $color-grey-4;
    border: none;
    color: $color-background;
    height: auto;
  }
}

.woof_block_html_items {
  .chosen-single {
    height: 35px !important;
    background: none !important;
    box-sizing: content-box !important;
    span {
    }
  }
}

.vi_wpvs_loop_variation_form {
  .vi-wpvs-option-wrap {
    padding: 5px 10px !important;
    font-size: 0.8rem !important;
  }
  .vi-wpvs-variation-attribute-type-button {
    margin-bottom: 0;
  }
}

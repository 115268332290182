.plazos {
  &__head {
    margin-bottom: $space-01;
  }

  &__titulo {
  }

  &__subtitulo {
  }

  &__body {
  }

  &__item {
    margin-bottom: $font-size-smaller;
    border-bottom: 1px dashed $color-dark;
  }

  &__item-titulo {
    font-weight: $font-weight-bold;
  }

  &__item-descripcion {
    margin-bottom: $font-size / 4;
  }
}

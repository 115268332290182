.grid-woo {
  display: flex;
  &__sidebar {
    display: block;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__sidebar-responsive {
    display: block;
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__content {
    width: 100%;
  }
}

.listado-categorias {
  margin-bottom: $space-2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: $space-1;
  @media screen and (max-width: 575px) {
    gap: $space-01;
  }
  &__item {
    padding: 10px 15px;
    border-radius: 4px;
    background-color: $color-dark;
    color: $color-white;
    transition: $trans;
    &:hover {
      background-color: $color-dark;
      color: $color-white;
      transition: $trans;
    }
    @media screen and (max-width: 575px) {
      padding: 6px 10px;
    }
  }
}

.widget--woo {
  margin-bottom: 0rem;
  @media (max-width: 575px) {
    margin-bottom: 1rem;
  }
  h4.widget__title {
    display: none;
  }
  input {
    box-shadow: none !important;
  }
}

.single-product {
  .grid-woo {
    display: block;
  }
  .grid-woo__sidebar-responsive,
  .grid-woo__sidebar {
    display: none;
  }
}

.tax-product_cat .grid-woo,
.post-type-archive-product .grid-woo {
  padding-top: 2rem !important;
  @media screen and (max-width: 767px) {
    padding-top: 0.5rem !important;
  }
}

.page-woocommerce {
  padding-top: 2rem;
  padding-bottom: 4rem;
  &--product {
    padding-top: 0;
    // background-color: $color-background;
  }
}

.term-description {
  margin-top: 2.5rem;
}

.widget-tallas {
  margin-bottom: 2rem;
  &__widget {
    width: 100%;
    @media screen and (min-width: 992px) {
      width: 30%;
    }
  }
}

ul.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;
  margin-top: 1rem;
  @media screen and (max-width: 575px) {
    // padding-left: 2rem;
    // padding-right: 2rem;
  }
  li.product {
    // padding: 1rem;
    background-color: $color-background;
    // border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .button {
      background: $color-grey-5;
      border-color: $color-grey-5;
      border-width: 2px !important;
      color: $color-background;
      border-style: solid;
      // @include input-buttons-sm;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      transition: $trans;
      &:hover {
        .bi {
          transition: $trans;
          margin-right: 15px;
        }
      }
      .bi {
        margin-right: 5px;
        margin-bottom: 5px;
        transition: $trans;
      }
    }
    .added_to_cart {
    }
  }

  .product_gama {
    position: absolute;
    z-index: 99;
    font-size: 0.9rem;
    border-radius: 4px;
    background-color: $color-dark;
    color: $color-white;
    border-color: $color-dark;
    display: inline;
    box-shadow: none;
    padding: 10px 15px;
    transform: translate(0.5rem, 0.5rem);
    @media screen and (max-width: 575px) {
      transform: translate(0, 0.5rem);
      padding: 6px 10px;
    }
  }

  .product__cat {
    margin-bottom: $font-size / 3;
    color: $color-secondary;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    @media (max-width: 575px) {
      margin-bottom: 0.2rem !important;
    }
  }

  .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    position: relative;
    text-align: left;

    @media (max-width: 575px) {
      align-items: center;
    }
    &:hover {
      .attachment-woocommerce_thumbnail {
        scale: 1.1;
        transition: $trans-2;
      }
    }
    .woocommerce-loop-product__img-container {
      overflow: hidden;
      margin-bottom: $space-1;
      @media screen and (max-width: 575px) {
        margin-bottom: $space-01;
      }
    }
    .onsale {
      position: absolute;
      background-color: $color-secondary-dark;
      color: $color-white;
      padding: 3px 8px 4px;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      top: 0.5rem;
      left: 0.5rem;
      z-index: 1;
      @media screen and (max-width: 575px) {
        font-size: 0.7rem;
        padding: 2px 8px 3px;
      }
    }
  }

  .woocommerce-loop-product__link .wp-post-image {
    width: 100%;
    height: auto;
    //border: 1px solid rgba($color: #000000, $alpha: 0.1);
    margin-bottom: 1rem;
    //border-radius: 4px;
  }

  .attachment-woocommerce_thumbnail {
    width: 100% !important;
    height: auto;
    transition: $trans-2;

    // border-radius: $border-radius-min;
    // border: 1px solid rgba($color: #000000, $alpha: 0.1);
    // border-top-left-radius: $border-radius;
    // border-top-right-radius: $border-radius;
    @media (max-width: 575px) {
      margin-bottom: 0 !important;
    }
  }

  .woocommerce-loop-product__title {
    font-family: $typo-secondary;
    font-size: $header-3;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: $space-05;
    display: block;
    color: $color-text-titles;
    @media (max-width: 575px) {
      font-size: $header-3;
      font-family: $typo-primary;
    }
  }

  .price {
    // @media (max-width: 575px) {
    //   display: flex;
    //   justify-content: flex-start;
    //   width: 100%;
    // }
    .woocommerce-Price-amount {
      color: $color-grey-3;
      font-size: $header-3-res;
    }

    .woocommerce-Price-currencySymbol {
      //font-size: $font-size / 1.5;
    }

    ins {
      text-decoration: none !important;
      bdi {
        color: $color-secondary-dark;
        font-weight: bold;
        font-size: 1.125rem;
        margin-left: 0.5rem;
      }
    }
    del {
    }
  }
}

.related ul.products .product_gama {
  position: absolute;
  top: 0;
  transform: translate(1rem, 1rem);
}

.columns-1 {
  grid-template-columns: 1fr;
  .product {
    width: 40%;
    margin: 0 auto;
  }
}

.columns-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: $space-4 $space-2;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 1rem;
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem 1rem;
  }
}

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem 2rem;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 1rem;
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem 1rem;
  }
}

.columns-5 {
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem 2rem;
  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem 1rem;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem 2rem;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 1rem;
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem 1rem;
  }
}

.columns-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem 1rem;
  }
}

.products {
  li.product-category {
    img {
      width: 100%;
      height: auto;
      // border: 0px solid rgba($color: #000000, $alpha: 0.1);
      margin-bottom: 1.5rem;
    }
    h2.woocommerce-loop-category__title {
      font-size: 1rem;
      color: $color-grey-5;
      text-align: center;
    }
  }
}

.term-description {
  margin-bottom: 1rem;
  font-size: 0.85rem;
}

.woocommerce-result-count {
  display: inline-block;
  color: $color-grey-5;
  margin-top: 10px;
  font-size: $font-size-small;

  @media (max-width: 575px) {
    font-size: $font-size-small;
    display: none;
  }
}

.woocommerce-ordering {
  display: inline-block;
  float: right;
  clear: both;
  @media (max-width: 991px) {
    float: none;
  }
}

/* Paginador -------------------- */

.woocommerce-pagination {
  text-align: center;

  ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid $color-dark;
    padding: 2px;
    border-radius: 3px;
  }

  li {
    display: inline-block;
  }

  li .page-numbers {
    padding: 10px 15px;
    background-color: $color-dark;
    border: 1px solid $color-dark;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 4px;
    font-size: 1.2rem;
  }

  li .page-numbers.current {
    background-color: transparent;
    color: $color-dark;
  }
  li .page-numbers:hover {
    background-color: transparent;
    color: $color-dark;
  }
}

.nav-legal {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    gap: $space-1;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: $space-05;
      align-items: center;
    }
    & > li.menu-item {
      a {
        color: $color-white;
        font-size: $font-size-small;
        text-decoration: none;
      }
    }
  }
}

.offcanvas-button-open {
  transition: $trans-5;
}

#offcanvasMenu {
  width: 100vw;
  height: 100vh;
  border: none;
}

.offcanvas {
  background-color: $color-background-offCanvas;

  &__top {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    padding-top: 1.6rem;
    padding-right: 2.3rem;
    padding-left: 2.3rem;
    @media screen and (max-width: 575px) {
      padding-top: 1.2rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    img {
      width: 180px;
      @media screen and (max-width: 480px) {
        width: 120px;
      }
    }
  }

  &__burger {
    justify-self: flex-start;
    line-height: 1;
    transition: $trans;
    overflow: hidden;
    display: flex;
    align-items: center;
    opacity: 0;
    &:hover {
      transform: scale(1.1);
      transition: $trans;
      .offcanvas__burger--text {
        opacity: 1;
        transition: $trans-2;
      }
    }
    i {
      color: $color-dark;
      font-size: 25px;
      transition: $trans;
      &:hover {
        color: $color-dark;
      }
      @media screen and (max-width: 575px) {
        font-size: 20px;
      }
    }
    &--text {
      margin-left: 0.5rem;
      opacity: 0;
      transition: $trans;
    }
  }

  &__body {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-4;
    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr 1.5fr;
      gap: $space-1;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  &__body--left {
    @media screen and (max-width: 575px) {
      display: none;
    }
  }
  &__body--right {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 575px) {
      padding-left: $space-1;
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: $space-5;
  }

  &__menu {
    width: 100%;
    //padding: 0 1.5rem;
    display: flex;
    justify-content: flex-start;
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    li.menu-item {
      margin-bottom: 0;
      overflow: hidden;
      a {
        // display: block;
        font-size: $display-3;
        text-transform: uppercase;
        text-decoration: none;
        color: $color-dark;
        line-height: 1;
        &:hover {
          color: lighten($color-dark, 10%);
        }
        @media screen and (max-width: 767px) {
          font-size: $header-2;
        }
        @media screen and (max-width: 575px) {
          font-size: $header-2-res;
        }
      }
    }
  }

  &__nav-link {
    transform: translateY(56px);
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: $space-3;
    opacity: 0;
  }

  &__contacto {
    font-size: $font-size-small;
    text-transform: uppercase;
    margin-bottom: $space-05;
    .widget-contacto__title {
      font-size: $font-size-small;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }
    a {
      text-transform: none;
      color: $color-text-body;
      &:hover {
        color: $color-text-body;
      }
    }
  }

  &__rs {
    .zoom-social-icons-list {
      margin-left: 0 !important;
      display: flex;
      justify-content: center;
      gap: $space-05;
    }

    .social-icon {
      font-size: 18px !important;
      padding: 0.5rem !important;
      background-color: $color-dark !important;
      color: $color-background-offCanvas !important;
      transition: $trans;
      @media screen and (max-width: 767px) {
        font-size: 18px !important;
        transition: $trans;
      }
      &:hover {
        background-color: $color-dark !important;
        color: $color-background-offCanvas !important;
        transition: $trans;
        box-shadow: $box-shadow-small;
        transform: translateY(-2px);
      }
    }
  }
}

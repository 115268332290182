.galeria {
  &__content {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-template-rows: auto;
    row-gap: $space-5 + $space-02;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 2px;
    }
  }

  &__item {
    @media screen and (min-width: 768px) {
      &:nth-child(1) {
        grid-column: 2 / span 6;
        grid-row: 1;
      }

      &:nth-child(2) {
        grid-column: 1 / span 5;
        grid-row: 2 / span 2;
      }

      &:nth-child(3) {
        // position: sticky;
        // top: 0;
        grid-column: 7 / span 2;
        grid-row: 2 / span 1;
      }

      &:nth-child(4) {
        grid-column: 3 / span 4;
        grid-row: 4 / span 2;
      }

      &:nth-child(5) {
        grid-column: 1 / span 3;
        grid-row: 6 / span 1;
      }

      &:nth-child(6) {
        grid-column: 5 / span 4;
        grid-row: 6 / span 1;
      }

      &:nth-child(7) {
        grid-column: 3 / span 4;
        grid-row: 7 / span 2;
      }
    }
  }

  &__item--video {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
  }

  &__img {
    width: 100%;
    height: auto;
  }
}

.feat {
  &__item {
    display: flex;
    margin-bottom: $space-5;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-2;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__card {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media screen and (min-width: 1400px) {
      grid-template-columns: 1.5fr 1fr;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 3rem;
    }
    &--11 {
      grid-template-columns: 1fr 1.5fr;
      @media screen and (min-width: 1400px) {
        grid-template-columns: 1fr 1.5fr;
      }
    }
    &--inverted {
      @media screen and (min-width: 1400px) {
        grid-template-columns: 1fr 1.5fr;
      }
      &.feat__card--11 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .feat__card-picture {
        order: 2;
        @media screen and (max-width: 991px) {
          order: 1;
        }
      }
      .feat__card-content {
        order: 1;
        padding: 0 4rem 0 0;
        @media screen and (max-width: 991px) {
          padding: 1.5rem 0;
          order: 2;
          @media screen and (max-width: 767px) {
            padding: 0.2rem 0;
          }
        }
      }
    }
  }

  &__card-picture {
    position: relative;
    @media screen and (max-width: 767px) {
      margin-bottom: 0.5rem;
    }
    img {
      position: relative;
      aspect-ratio: 16 / 10;
      width: 100%;
      height: auto;
      object-fit: cover;
      @media screen and (max-width: 1280px) {
        aspect-ratio: 16 / 12;
      }
      @media screen and (max-width: 991px) {
        aspect-ratio: 16 / 11;
      }
    }
  }

  &__card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 4rem;
    @media screen and (max-width: 991px) {
      padding: 1.5rem 0;
    }
    @media screen and (max-width: 767px) {
      padding: 0.2rem 0;
    }
    &--center {
      align-items: center;
      text-align: center;
    }
  }

  &__card-title {
    font-family: $typo-secondary;
    font-size: $header-2;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    color: $color-secondary;
    letter-spacing: -1px;
    margin-bottom: 1rem;
    @media screen and (max-width: 1440px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }

  &__card-description {
    color: $color-white;
    line-height: 1.5;
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        position: relative;
        padding-left: $space-1;
        &::before {
          position: absolute;
          left: 0;
          top: 3px;
          display: inline-block;
          content: "";
          color: $color-white;
          vertical-align: -0.125em;
          background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z' clip-rule='evenodd'/></svg>");
          background-repeat: no-repeat;
          background-size: 1rem 1rem;
          width: 1rem;
          height: 1rem;
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

.titulos__titulo {
  &--home1,
  &--home-proyectos {
    transform: translateY(90px);
    opacity: 0;
  }
}

.h-tienda {
  padding: $space-5 0;
  @media screen and (max-width: 767px) {
    padding: $space-4 0;
  }
  @media screen and (max-width: 575px) {
    padding: $space-3 0;
  }

  &__productos {
    max-width: calc(100vw - 10%);
    margin-right: 5%;
    margin-left: 5%;
  }
}

.loop-tienda {
  max-width: calc(100vw - 10%);
  margin-right: 5%;
  margin-left: 5%;
}

.img-full {
  padding: $space-4 0;

  &__container {
    max-width: calc(100vw - 24%);
    margin-right: 12%;
    margin-left: 12%;
    position: relative;
    // width: 680px;
    // height: 810px;
    // top: 100px;
    overflow: hidden;
    @media screen and (max-width: 991px) {
      max-width: calc(100vw - 8%);
      margin-right: 4%;
      margin-left: 4%;
    }
  }
  &__img {
    width: 100%;
    height: auto;
    scale: 1.2 !important;
    will-change: transform;
  }
}

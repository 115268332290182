.inspiracion {
  &__content {
  }

  .ff-stream {
    padding: 0 !important;
    min-height: 100% !important;
    background-color: transparent !important;
  }
  .ff-stream-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
  .ff-item {
    box-shadow: $box-shadow;
  }
  .ff-loadmore-wrapper {
    visibility: hidden !important;
    display: none;
  }
}

.ff-slideshow .ff-supports-comments .ff-comments-list {
  display: none !important;
}

.cta {
  padding: $space-6 0 $space-5 0;
  @media screen and (max-width: 767px) {
    padding-top: $space-5;
  }
  @media screen and (max-width: 575px) {
    padding-top: $space-4 + $space-01;
    padding-bottom: $space-2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: 600px) {
      max-width: calc(100vw - 10%);
    }
  }
  &__title-container {
    overflow: hidden;
  }

  &__title {
    transform: translateY(170px);
    font-size: $display-3;
    font-family: $typo-secondary;
    text-transform: uppercase;
    color: $color-text-titles;
    line-height: 1.3;
    margin-bottom: $space-01;
    opacity: 0;
    @media screen and (max-width: 767px) {
      font-size: $display-4;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
    }
  }
  &__description {
  }

  &__actions {
  }
}

html {
  scroll-behavior: smooth;
}
body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-text-body;
  font-weight: $font-weight-normal;
  font-family: $typo-primary;

  /* Font Smoothing */
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

// .scroll-viewport {
//   overflow: hidden;
//   position: fixed;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }

// .scroll-container {
//   width: 100%;
//   position: absolute;
//   overflow: hidden;
//   z-index: 10;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   backface-visibility: hidden;
//   transform-style: preserve-3d;
// }

// .content-container {
//   width: 100%;
// }

a {
  color: $color-secondary-dark;
  transition: $trans;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    transition: $trans;
    color: $color-secondary-dark;
  }
}

.oculto {
  display: none !important;
  transition: $trans;
}

.opacity {
  transition: $trans-5;
  &--0 {
    opacity: 0;
  }
}

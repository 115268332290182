.header-all {
  font-weight: $font-weight-bold;
  margin-bottom: $space-01;
  margin-top: 0;
  line-height: 1.4;
  font-family: $typo-secondary;
  color: $color-text-titles;
  text-transform: uppercase;
}

.header-1 {
  font-size: $header-1;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 2.1875rem;
  }
}
.header-2 {
  font-size: $header-2;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.875rem;
  }
}
.header-3 {
  font-size: $header-3;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.25rem;
  }
}
.header-4 {
  font-size: $header-4;
  @extend .header-all;
}
.header-5 {
  font-size: $header-5;
  @extend .header-all;
}
.header-6 {
  font-size: $header-6;
  @extend .header-all;
}

.page-body {
  p {
    font-size: $font-size;
  }
}

.titulos {
  margin-bottom: $space-3;
  @media screen and (max-width: 767px) {
    margin-bottom: $space-2;
  }
  &--center {
    text-align: center;
  }

  &--600 {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 600px) {
      max-width: calc(100vw - 10%);
    }
  }

  &--50 {
    @media screen and (min-width: 992px) {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--up {
    text-transform: uppercase;
  }

  &__pretitulo {
    font-size: $font-size;
    margin-bottom: $font-size;
    color: $color-secondary;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 575px) {
      margin-bottom: $space-05;
    }
  }

  &__titulo-container {
    overflow: hidden;
  }

  &__titulo {
    font-size: $display-3;
    font-family: $typo-secondary;
    text-transform: uppercase;
    color: $color-text-titles;
    line-height: 1.3;
    margin-bottom: $space-01;
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
    }
    @media screen and (max-width: 575px) {
      line-height: 1.2;
    }
  }

  &__subtitulo {
    font-size: $header-2;
    color: $color-text-titles;
    margin-bottom: $space-01;
    line-height: 1;
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
    }
  }

  &__actions {
    margin-top: $space-1 !important;
  }

  &__description {
    color: $color-grey-4;
    p {
      margin-bottom: 0;
    }
  }
  &--light {
    .titulos__pretitulo {
      color: $color-white;
    }
    .titulos__titulo {
      color: $color-white;
    }
    .titulos__description {
      color: $color-white;
    }
  }
  &--light-accent {
    .titulos__pretitulo {
      color: $color-secondary-light;
    }
    .titulos__titulo,
    .titulos__descripcion {
      color: $color-white;
    }
  }
  &--light-dark {
    .titulos__pretitulo {
      color: $color-dark;
    }
    .titulos__titulo {
      color: $color-white;
    }
  }

  &--mb-0 {
    margin-bottom: 0;
  }
  &--mb-01 {
    margin-bottom: $space-01;
  }
  &--mb-1 {
    margin-bottom: $space-1;
  }
}

.title-section {
  color: $color-secondary;
  margin-bottom: $space-1;
  font-family: $typo-secondary;
  font-size: $header-2;
  &--sm {
    font-size: $header-3;
    margin-bottom: $space-01;
  }
}

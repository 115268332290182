@charset "UTF-8";
/*!
 * Bootstrap v5.2.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-heading-color: ;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color); }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--bs-link-color);
  text-decoration: underline; }
  a:hover {
    color: var(--bs-link-hover-color); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: rgba(var(--bs-body-color-rgb), 0.75);
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: calc(2 * $table-border-width) solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgba(var(--bs-body-color-rgb), 0.75); }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.375rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.375rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #198754; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check:focus + .btn, .btn:focus {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus,
    .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd; }

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d; }

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754; }

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107; }

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f9fafb;
  --bs-btn-hover-border-color: #f9fafb;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f9fafb;
  --bs-btn-active-border-color: #f9fafb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1c1f23;
  --bs-btn-hover-border-color: #1a1e21;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1a1e21;
  --bs-btn-active-border-color: #191c1f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529; }

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  text-decoration: underline; }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem; }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: 0.375rem; }
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd; }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius); }
    .nav-pills .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: calc($pagination-border-width * -1); }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem; }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem; }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius, 0); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius, 0); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: #084298;
  --bs-alert-bg: #cfe2ff;
  --bs-alert-border-color: #b6d4fe; }
  .alert-primary .alert-link {
    color: #06357a; }

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8; }
  .alert-secondary .alert-link {
    color: #34383c; }

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc; }
  .alert-success .alert-link {
    color: #0c4128; }

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb; }
  .alert-info .alert-link {
    color: #04414d; }

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5; }
  .alert-warning .alert-link {
    color: #523e02; }

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7; }
  .alert-danger .alert-link {
    color: #6a1a21; }

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #4f5050; }

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf; }
  .alert-dark .alert-link {
    color: #101214; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(var(--bs-list-group-border-width) * -1);
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(var(--bs-list-group-border-width) * -1);
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #084298;
    background-color: #bacbe6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298; }

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #41464b;
    background-color: #cbccce; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0f5132;
    background-color: #bcd0c7; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #055160;
    background-color: #badce3; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #636464;
    background-color: #e5e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #141619;
    background-color: #bebebf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  position: absolute;
  z-index: 1090;
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(var(--bs-toast-padding-x) * -.5);
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(var(--bs-modal-header-padding-y) * -.5) calc(var(--bs-modal-header-padding-x) * -.5) calc(var(--bs-modal-header-padding-y) * -.5) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0; }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0; }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius, 0); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: var(--bs-heading-color);
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(var(--bs-popover-arrow-width) * -.5);
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; }
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; }
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; }
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; }
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; }
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(var(--bs-offcanvas-padding-y) * -.5);
    margin-right: calc(var(--bs-offcanvas-padding-x) * -.5);
    margin-bottom: calc(var(--bs-offcanvas-padding-y) * -.5); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: #0d6efd !important; }
  .link-primary:hover, .link-primary:focus {
    color: #0a58ca !important; }

.link-secondary {
  color: #6c757d !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #565e64 !important; }

.link-success {
  color: #198754 !important; }
  .link-success:hover, .link-success:focus {
    color: #146c43 !important; }

.link-info {
  color: #0dcaf0 !important; }
  .link-info:hover, .link-info:focus {
    color: #3dd5f3 !important; }

.link-warning {
  color: #ffc107 !important; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39 !important; }

.link-danger {
  color: #dc3545 !important; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37 !important; }

.link-light {
  color: #f8f9fa !important; }
  .link-light:hover, .link-light:focus {
    color: #f9fafb !important; }

.link-dark {
  color: #212529 !important; }
  .link-dark:hover, .link-dark:focus {
    color: #1a1e21 !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), 0.75) !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

html {
  scroll-behavior: smooth; }

body {
  background-color: #f8f3ed;
  font-size: 1rem;
  color: #424142;
  font-weight: 400;
  font-family: "PT Serif", serif;
  /* Font Smoothing */
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased; }

a {
  color: #83031f;
  transition: 0.2s all ease-in-out;
  text-decoration: none; }
  a:hover {
    text-decoration: none;
    transition: 0.2s all ease-in-out;
    color: #83031f; }

.oculto {
  display: none !important;
  transition: 0.2s all ease-in-out; }

.opacity {
  transition: 1s all ease-in-out; }
  .opacity--0 {
    opacity: 0; }

.cursor-pointer {
  cursor: pointer; }

.bg-primary {
  background: #886d50 !important; }

.bg-primary-light {
  background: #978671 !important; }

.bg-primary-dark {
  background: #56432f !important; }

.bg-secondary {
  background: #ba042c !important; }

.bg-secondary-light {
  background: #eba1b1 !important; }

.bg-secondary-dark {
  background: #83031f !important; }

.bg-accent {
  background: #2cc374 !important; }

.bg-accent-light {
  background: #8fd6b1 !important; }

.bg-accent-dark {
  background: #0b9d51 !important; }

.bg-white {
  background: #fff !important; }

.bg-dark {
  background: #1e2325 !important; }

.bg-darker {
  background: #1a1e20 !important; }

.bg-grey-1 {
  background: #262626 !important; }

.bg-grey-2 {
  background: #424142 !important; }

.bg-grey-3 {
  background: #7f7f7f !important; }

.bg-grey-4 {
  background: #b6b6b6 !important; }

.bg-grey-5 {
  background: #d7d7d7 !important; }

.bg-grey-6 {
  background: #f5f5f5 !important; }

.color-primary {
  color: #886d50 !important; }

.color-primary-light {
  color: #978671 !important; }

.color-primary-dark {
  color: #56432f !important; }

.color-secondary {
  color: #ba042c !important; }

.color-secondary-light {
  color: #eba1b1 !important; }

.color-secondary-dark {
  color: #83031f !important; }

.color-accent {
  color: #2cc374 !important; }

.color-accent-light {
  color: #8fd6b1 !important; }

.color-accent-dark {
  color: #0b9d51 !important; }

.color-white {
  color: #fff !important; }

.color-dark {
  color: #1e2325 !important; }

.color-darker {
  color: #1a1e20 !important; }

.color-grey-1 {
  color: #262626 !important; }

.color-grey-2 {
  color: #424142 !important; }

.color-grey-3 {
  color: #7f7f7f !important; }

.color-grey-4 {
  color: #b6b6b6 !important; }

.color-grey-5 {
  color: #d7d7d7 !important; }

.color-grey-6 {
  color: #f5f5f5 !important; }

.pad-t-0 {
  padding-top: 0px; }
  @media screen and (max-width: 991px) {
    .pad-t-0 {
      padding-top: 0px !important; } }
  @media screen and (max-width: 575px) {
    .pad-t-0 {
      padding-top: 0px !important; } }

.mar-t-0 {
  margin-top: 0px; }
  @media screen and (max-width: 991px) {
    .mar-t-0 {
      margin-top: 0px !important; } }
  @media screen and (max-width: 575px) {
    .mar-t-0 {
      margin-top: 0px !important; } }

.pad-t-01 {
  padding-top: 1rem; }
  @media screen and (max-width: 991px) {
    .pad-t-01 {
      padding-top: 0.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-t-01 {
      padding-top: 0.5rem !important; } }

.mar-t-01 {
  margin-top: 1rem; }
  @media screen and (max-width: 991px) {
    .mar-t-01 {
      margin-top: 0.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-t-01 {
      margin-top: 0.5rem !important; } }

.pad-t-1 {
  padding-top: 1.5rem; }
  @media screen and (max-width: 991px) {
    .pad-t-1 {
      padding-top: 1rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-t-1 {
      padding-top: 0.75rem !important; } }

.mar-t-1 {
  margin-top: 1.5rem; }
  @media screen and (max-width: 991px) {
    .mar-t-1 {
      margin-top: 1rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-t-1 {
      margin-top: 0.75rem !important; } }

.pad-t-2 {
  padding-top: 2.5rem; }
  @media screen and (max-width: 991px) {
    .pad-t-2 {
      padding-top: 1.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-t-2 {
      padding-top: 1.25rem !important; } }

.mar-t-2 {
  margin-top: 2.5rem; }
  @media screen and (max-width: 991px) {
    .mar-t-2 {
      margin-top: 1.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-t-2 {
      margin-top: 1.25rem !important; } }

.pad-t-3 {
  padding-top: 4rem; }
  @media screen and (max-width: 991px) {
    .pad-t-3 {
      padding-top: 2.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-t-3 {
      padding-top: 2rem !important; } }

.mar-t-3 {
  margin-top: 4rem; }
  @media screen and (max-width: 991px) {
    .mar-t-3 {
      margin-top: 2.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-t-3 {
      margin-top: 2rem !important; } }

.pad-t-4 {
  padding-top: 5rem; }
  @media screen and (max-width: 991px) {
    .pad-t-4 {
      padding-top: 3.33333rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-t-4 {
      padding-top: 2.5rem !important; } }

.mar-t-4 {
  margin-top: 5rem; }
  @media screen and (max-width: 991px) {
    .mar-t-4 {
      margin-top: 3.33333rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-t-4 {
      margin-top: 2.5rem !important; } }

.pad-t-5 {
  padding-top: 7.5rem; }
  @media screen and (max-width: 991px) {
    .pad-t-5 {
      padding-top: 5rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-t-5 {
      padding-top: 3.75rem !important; } }

.mar-t-5 {
  margin-top: 7.5rem; }
  @media screen and (max-width: 991px) {
    .mar-t-5 {
      margin-top: 5rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-t-5 {
      margin-top: 3.75rem !important; } }

.pad-b-0 {
  padding-bottom: 0px; }
  @media screen and (max-width: 991px) {
    .pad-b-0 {
      padding-bottom: 0px !important; } }
  @media screen and (max-width: 575px) {
    .pad-b-0 {
      padding-bottom: 0px !important; } }

.mar-b-0 {
  margin-bottom: 0px; }
  @media screen and (max-width: 991px) {
    .mar-b-0 {
      margin-bottom: 0px !important; } }
  @media screen and (max-width: 575px) {
    .mar-b-0 {
      margin-bottom: 0px !important; } }

.pad-b-01 {
  padding-bottom: 1rem; }
  @media screen and (max-width: 991px) {
    .pad-b-01 {
      padding-bottom: 0.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-b-01 {
      padding-bottom: 0.5rem !important; } }

.mar-b-01 {
  margin-bottom: 1rem; }
  @media screen and (max-width: 991px) {
    .mar-b-01 {
      margin-bottom: 0.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-b-01 {
      margin-bottom: 0.5rem !important; } }

.pad-b-1 {
  padding-bottom: 1.5rem; }
  @media screen and (max-width: 991px) {
    .pad-b-1 {
      padding-bottom: 1rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-b-1 {
      padding-bottom: 0.75rem !important; } }

.mar-b-1 {
  margin-bottom: 1.5rem; }
  @media screen and (max-width: 991px) {
    .mar-b-1 {
      margin-bottom: 1rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-b-1 {
      margin-bottom: 0.75rem !important; } }

.pad-b-2 {
  padding-bottom: 2.5rem; }
  @media screen and (max-width: 991px) {
    .pad-b-2 {
      padding-bottom: 1.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-b-2 {
      padding-bottom: 1.25rem !important; } }

.mar-b-2 {
  margin-bottom: 2.5rem; }
  @media screen and (max-width: 991px) {
    .mar-b-2 {
      margin-bottom: 1.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-b-2 {
      margin-bottom: 1.25rem !important; } }

.pad-b-3 {
  padding-bottom: 4rem; }
  @media screen and (max-width: 991px) {
    .pad-b-3 {
      padding-bottom: 2.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-b-3 {
      padding-bottom: 2rem !important; } }

.mar-b-3 {
  margin-bottom: 4rem; }
  @media screen and (max-width: 991px) {
    .mar-b-3 {
      margin-bottom: 2.66667rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-b-3 {
      margin-bottom: 2rem !important; } }

.pad-b-4 {
  padding-bottom: 5rem; }
  @media screen and (max-width: 991px) {
    .pad-b-4 {
      padding-bottom: 3.33333rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-b-4 {
      padding-bottom: 2.5rem !important; } }

.mar-b-4 {
  margin-bottom: 5rem; }
  @media screen and (max-width: 991px) {
    .mar-b-4 {
      margin-bottom: 3.33333rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-b-4 {
      margin-bottom: 2.5rem !important; } }

.pad-b-5 {
  padding-bottom: 7.5rem; }
  @media screen and (max-width: 991px) {
    .pad-b-5 {
      padding-bottom: 5rem !important; } }
  @media screen and (max-width: 575px) {
    .pad-b-5 {
      padding-bottom: 3.75rem !important; } }

.mar-b-5 {
  margin-bottom: 7.5rem; }
  @media screen and (max-width: 991px) {
    .mar-b-5 {
      margin-bottom: 5rem !important; } }
  @media screen and (max-width: 575px) {
    .mar-b-5 {
      margin-bottom: 3.75rem !important; } }

.pad-0 {
  padding: 0px 0; }
  @media screen and (max-width: 991px) {
    .pad-0 {
      padding: 0px 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-0 {
      padding: 0px 0 !important; } }

.mar-0 {
  margin: 0px 0; }
  @media screen and (max-width: 991px) {
    .mar-0 {
      margin: 0px 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-0 {
      margin: 0px 0 !important; } }

.pad-01 {
  padding: 1rem 0; }
  @media screen and (max-width: 991px) {
    .pad-01 {
      padding: 0.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-01 {
      padding: 0.5rem 0 !important; } }

.mar-01 {
  margin: 1rem 0; }
  @media screen and (max-width: 991px) {
    .mar-01 {
      margin: 0.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-01 {
      margin: 0.5rem 0 !important; } }

.pad-1 {
  padding: 1.5rem 0; }
  @media screen and (max-width: 991px) {
    .pad-1 {
      padding: 1rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-1 {
      padding: 0.75rem 0 !important; } }

.mar-1 {
  margin: 1.5rem 0; }
  @media screen and (max-width: 991px) {
    .mar-1 {
      margin: 1rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-1 {
      margin: 0.75rem 0 !important; } }

.pad-2 {
  padding: 2.5rem 0; }
  @media screen and (max-width: 991px) {
    .pad-2 {
      padding: 1.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-2 {
      padding: 1.25rem 0 !important; } }

.mar-2 {
  margin: 2.5rem 0; }
  @media screen and (max-width: 991px) {
    .mar-2 {
      margin: 1.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-2 {
      margin: 1.25rem 0 !important; } }

.pad-3 {
  padding: 4rem 0; }
  @media screen and (max-width: 991px) {
    .pad-3 {
      padding: 2.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-3 {
      padding: 2rem 0 !important; } }

.mar-3 {
  margin: 4rem 0; }
  @media screen and (max-width: 991px) {
    .mar-3 {
      margin: 2.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-3 {
      margin: 2rem 0 !important; } }

.pad-4 {
  padding: 5rem 0; }
  @media screen and (max-width: 991px) {
    .pad-4 {
      padding: 3.33333rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-4 {
      padding: 2.5rem 0 !important; } }

.mar-4 {
  margin: 5rem 0; }
  @media screen and (max-width: 991px) {
    .mar-4 {
      margin: 3.33333rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-4 {
      margin: 2.5rem 0 !important; } }

.pad-5 {
  padding: 7.5rem 0; }
  @media screen and (max-width: 991px) {
    .pad-5 {
      padding: 5rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-5 {
      padding: 3.75rem 0 !important; } }

.mar-5 {
  margin: 7.5rem 0; }
  @media screen and (max-width: 991px) {
    .mar-5 {
      margin: 5rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-5 {
      margin: 3.75rem 0 !important; } }

.pad-0 {
  padding: 0px 0; }
  @media screen and (max-width: 991px) {
    .pad-0 {
      padding: 0px 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-0 {
      padding: 0px 0 !important; } }

.mar-0 {
  margin: 0px 0; }
  @media screen and (max-width: 991px) {
    .mar-0 {
      margin: 0px 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-0 {
      margin: 0px 0 !important; } }

.pad-01 {
  padding: 1rem 0; }
  @media screen and (max-width: 991px) {
    .pad-01 {
      padding: 0.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-01 {
      padding: 0.5rem 0 !important; } }

.mar-01 {
  margin: 1rem 0; }
  @media screen and (max-width: 991px) {
    .mar-01 {
      margin: 0.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-01 {
      margin: 0.5rem 0 !important; } }

.pad-1 {
  padding: 1.5rem 0; }
  @media screen and (max-width: 991px) {
    .pad-1 {
      padding: 1rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-1 {
      padding: 0.75rem 0 !important; } }

.mar-1 {
  margin: 1.5rem 0; }
  @media screen and (max-width: 991px) {
    .mar-1 {
      margin: 1rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-1 {
      margin: 0.75rem 0 !important; } }

.pad-2 {
  padding: 2.5rem 0; }
  @media screen and (max-width: 991px) {
    .pad-2 {
      padding: 1.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-2 {
      padding: 1.25rem 0 !important; } }

.mar-2 {
  margin: 2.5rem 0; }
  @media screen and (max-width: 991px) {
    .mar-2 {
      margin: 1.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-2 {
      margin: 1.25rem 0 !important; } }

.pad-3 {
  padding: 4rem 0; }
  @media screen and (max-width: 991px) {
    .pad-3 {
      padding: 2.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-3 {
      padding: 2rem 0 !important; } }

.mar-3 {
  margin: 4rem 0; }
  @media screen and (max-width: 991px) {
    .mar-3 {
      margin: 2.66667rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-3 {
      margin: 2rem 0 !important; } }

.pad-4 {
  padding: 5rem 0; }
  @media screen and (max-width: 991px) {
    .pad-4 {
      padding: 3.33333rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-4 {
      padding: 2.5rem 0 !important; } }

.mar-4 {
  margin: 5rem 0; }
  @media screen and (max-width: 991px) {
    .mar-4 {
      margin: 3.33333rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-4 {
      margin: 2.5rem 0 !important; } }

.pad-5 {
  padding: 7.5rem 0; }
  @media screen and (max-width: 991px) {
    .pad-5 {
      padding: 5rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .pad-5 {
      padding: 3.75rem 0 !important; } }

.mar-5 {
  margin: 7.5rem 0; }
  @media screen and (max-width: 991px) {
    .mar-5 {
      margin: 5rem 0 !important; } }
  @media screen and (max-width: 575px) {
    .mar-5 {
      margin: 3.75rem 0 !important; } }

@media screen and (min-width: 1401px) {
  .container--max {
    max-width: 1600px; } }

.container--res {
  max-width: 1240px; }
  @media screen and (max-width: 991px) {
    .container--res {
      max-width: calc(100vw - 2rem); } }
  @media screen and (max-width: 575px) {
    .container--res {
      max-width: 100vw; } }

.img-color-reduced {
  filter: saturate(56%); }

.font-size-big {
  font-size: 1.25rem; }

.header-all, .header-1, .header-2, .header-3, .header-4, .header-5, .header-6 {
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 0;
  line-height: 1.4;
  font-family: "Prata", serif;
  color: #1e0028;
  text-transform: uppercase; }

.header-1 {
  font-size: 3rem; }
  @media screen and (max-width: 767px) {
    .header-1 {
      font-size: 2.1875rem; } }

.header-2 {
  font-size: 2.1875rem; }
  @media screen and (max-width: 767px) {
    .header-2 {
      font-size: 1.875rem; } }

.header-3 {
  font-size: 1.5rem; }
  @media screen and (max-width: 767px) {
    .header-3 {
      font-size: 1.25rem; } }

.header-4 {
  font-size: 1.25rem; }

.header-5 {
  font-size: 1rem; }

.header-6 {
  font-size: 0.87rem; }

.page-body p {
  font-size: 1rem; }

.titulos {
  margin-bottom: 4rem; }
  @media screen and (max-width: 767px) {
    .titulos {
      margin-bottom: 2.5rem; } }
  .titulos--center {
    text-align: center; }
  .titulos--600 {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 600px) {
      .titulos--600 {
        max-width: calc(100vw - 10%); } }
  @media screen and (min-width: 992px) {
    .titulos--50 {
      width: 50%;
      margin-left: auto;
      margin-right: auto; } }
  .titulos--up {
    text-transform: uppercase; }
  .titulos__pretitulo {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #ba042c;
    text-transform: uppercase;
    font-weight: 600; }
    @media screen and (max-width: 575px) {
      .titulos__pretitulo {
        margin-bottom: 0.5rem; } }
  .titulos__titulo-container {
    overflow: hidden; }
  .titulos__titulo {
    font-size: 3.45rem;
    font-family: "Prata", serif;
    text-transform: uppercase;
    color: #1e0028;
    line-height: 1.3;
    margin-bottom: 1rem; }
    @media screen and (max-width: 991px) {
      .titulos__titulo {
        font-size: 2.5rem; } }
    @media screen and (max-width: 575px) {
      .titulos__titulo {
        line-height: 1.2; } }
  .titulos__subtitulo {
    font-size: 2.1875rem;
    color: #1e0028;
    margin-bottom: 1rem;
    line-height: 1; }
    @media screen and (max-width: 991px) {
      .titulos__subtitulo {
        font-size: 1.875rem; } }
  .titulos__actions {
    margin-top: 1.5rem !important; }
  .titulos__description {
    color: #b6b6b6; }
    .titulos__description p {
      margin-bottom: 0; }
  .titulos--light .titulos__pretitulo {
    color: #fff; }
  .titulos--light .titulos__titulo {
    color: #fff; }
  .titulos--light .titulos__description {
    color: #fff; }
  .titulos--light-accent .titulos__pretitulo {
    color: #eba1b1; }
  .titulos--light-accent .titulos__titulo,
  .titulos--light-accent .titulos__descripcion {
    color: #fff; }
  .titulos--light-dark .titulos__pretitulo {
    color: #1e2325; }
  .titulos--light-dark .titulos__titulo {
    color: #fff; }
  .titulos--mb-0 {
    margin-bottom: 0; }
  .titulos--mb-01 {
    margin-bottom: 1rem; }
  .titulos--mb-1 {
    margin-bottom: 1.5rem; }

.title-section {
  color: #ba042c;
  margin-bottom: 1.5rem;
  font-family: "Prata", serif;
  font-size: 2.1875rem; }
  .title-section--sm {
    font-size: 1.5rem;
    margin-bottom: 1rem; }

.header {
  padding: 1rem 0;
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: 0.2s all ease-in-out; }
  @media screen and (max-width: 575px) {
    .header {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem; } }
  .header--sticky {
    padding: 0.5rem 0;
    transition: 0.2s all ease-in-out; }
    .header--sticky .logo {
      width: 120px !important;
      transition: 0.2s all ease-in-out; }
    .header--sticky .header__burger i {
      font-size: 2rem;
      transition: 0.2s all ease-in-out; }
    .header--sticky .header__woo-item--carrito a {
      font-size: 1.4rem;
      transition: 0.2s all ease-in-out; }
  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem; }
    @media screen and (max-width: 575px) {
      .header__container {
        padding-left: 1rem;
        padding-right: 1rem; } }
  .header__burger {
    line-height: 1;
    transition: 0.2s all ease-in-out;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: 0.3s all ease-in-out;
    width: 120px; }
    @media screen and (max-width: 575px) {
      .header__burger {
        width: 90px; } }
    .header__burger .offcanvas-button-open {
      background-color: #f8f3ed;
      padding: 0.4rem;
      border-radius: 4rem;
      transition: 0.2s all ease-in-out; }
    .header__burger:hover {
      transform: scale(1.1);
      transition: 0.2s all ease-in-out;
      background-color: #f8f3ed;
      padding: 0.4rem;
      border-radius: 4rem; }
      .header__burger:hover .offcanvas-button-open {
        padding: 0; }
      .header__burger:hover .header__burger--text {
        opacity: 1;
        transition: 0.2s all ease-in-out; }
    .header__burger i {
      color: #1e2325;
      font-size: 25px;
      transition: 0.2s all ease-in-out; }
      .header__burger i:hover {
        color: #1e2325; }
      @media screen and (max-width: 575px) {
        .header__burger i {
          font-size: 20px; } }
    .header__burger--text {
      margin-left: 0.5rem;
      opacity: 0;
      transition: 0.2s all ease-in-out; }
  .header__logo .logo {
    width: 160px;
    height: auto;
    transition: 0.2s all ease-in-out; }
    @media screen and (max-width: 575px) {
      .header__logo .logo {
        width: 140px;
        transition: 0.2s all ease-in-out; } }
  .header__woo {
    display: flex;
    justify-content: flex-end;
    width: 120px; }
    @media screen and (max-width: 575px) {
      .header__woo {
        width: 90px; } }
  .header__woo-item {
    padding: 0.5rem 0.5rem;
    background-color: #f8f3ed;
    padding: 0.4rem 0.5rem;
    border-radius: 4rem; }
    @media screen and (max-width: 1220px) {
      .header__woo-item {
        padding-top: 0;
        padding-bottom: 0; } }
    .header__woo-item a i {
      line-height: 1;
      font-size: 2rem;
      color: #1e2325; }
      @media screen and (max-width: 575px) {
        .header__woo-item a i {
          font-size: 28px; } }
    .header__woo-item a:hover {
      transform: scale(1.2); }
    @media screen and (max-width: 1220px) {
      .header__woo-item a {
        font-size: 1.7rem; } }
  .header__woo-item--carrito {
    transition: 0.2s all ease-in-out; }
    .header__woo-item--carrito:hover {
      transform: scale(1.1);
      transition: 0.2s all ease-in-out; }

.sticky-header-item {
  position: fixed;
  z-index: 999;
  transition: 0.2s all ease-in-out; }
  .sticky-header-item--burger {
    top: 1.3rem;
    left: 2rem; }
    @media screen and (max-width: 991px) {
      .sticky-header-item--burger {
        top: 1.1rem; } }
    @media screen and (max-width: 575px) {
      .sticky-header-item--burger {
        top: 1rem;
        left: 1rem; } }
  .sticky-header-item--cart {
    top: 1rem;
    right: 2rem; }
    @media screen and (max-width: 575px) {
      .sticky-header-item--cart {
        top: 0.75rem;
        right: 1rem; } }

.footer {
  padding: 5rem 0 2.5rem;
  background-color: #f8f3ed;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px; }
  @media screen and (max-width: 480px) {
    .footer {
      padding-top: 2.5rem; } }
  .footer__item--social .zoom-social-icons-list {
    margin-left: 0 !important;
    display: flex;
    justify-content: center;
    gap: 0.5rem; }
  .footer__item--social .social-icon {
    font-size: 24px !important;
    padding: 0.75rem !important;
    background-color: #1e2325 !important;
    color: #f8f3ed !important;
    transition: 0.2s all ease-in-out; }
    @media screen and (max-width: 767px) {
      .footer__item--social .social-icon {
        font-size: 24px !important;
        transition: 0.2s all ease-in-out; } }
    .footer__item--social .social-icon:hover {
      background-color: #1e2325 !important;
      color: #f8f3ed !important;
      transition: 0.2s all ease-in-out;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px); }
  .footer__item--nav {
    display: flex;
    justify-content: center;
    gap: 0.5rem 1.5rem;
    text-align: center; }
    @media screen and (max-width: 575px) {
      .footer__item--nav {
        flex-wrap: wrap; } }
  .footer__item .widget_nav_menu ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    gap: 0.5rem 1.5rem;
    text-align: center; }
    .footer__item .widget_nav_menu ul li a {
      color: #424142; }
    @media screen and (max-width: 575px) {
      .footer__item .widget_nav_menu ul {
        flex-wrap: wrap; } }
  .footer__shop .widget_nav_menu ul li a {
    color: #b6b6b6; }
  .footer__copy {
    color: #424142; }

.widget-footer__title {
  text-align: center;
  color: #1e0028; }

.footer-basic footer {
  background-color: transparent; }

.footer-basic .copyright {
  font-size: 2rem;
  color: #fff; }

.nav-legal__items {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  gap: 1.5rem; }
  @media screen and (max-width: 480px) {
    .nav-legal__items {
      flex-direction: column;
      gap: 0.5rem;
      align-items: center; } }
  .nav-legal__items > li.menu-item a {
    color: #fff;
    font-size: 0.9375rem;
    text-decoration: none; }

.offcanvas-button-open {
  transition: 1s all ease-in-out; }

#offcanvasMenu {
  width: 100vw;
  height: 100vh;
  border: none; }

.offcanvas {
  background-color: #d4d3cb; }
  .offcanvas__top {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    padding-top: 1.6rem;
    padding-right: 2.3rem;
    padding-left: 2.3rem; }
    @media screen and (max-width: 575px) {
      .offcanvas__top {
        padding-top: 1.2rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem; } }
  .offcanvas__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0; }
    .offcanvas__logo img {
      width: 180px; }
      @media screen and (max-width: 480px) {
        .offcanvas__logo img {
          width: 120px; } }
  .offcanvas__burger {
    justify-self: flex-start;
    line-height: 1;
    transition: 0.2s all ease-in-out;
    overflow: hidden;
    display: flex;
    align-items: center;
    opacity: 0; }
    .offcanvas__burger:hover {
      transform: scale(1.1);
      transition: 0.2s all ease-in-out; }
      .offcanvas__burger:hover .offcanvas__burger--text {
        opacity: 1;
        transition: 0.3s all ease-in-out; }
    .offcanvas__burger i {
      color: #1e2325;
      font-size: 25px;
      transition: 0.2s all ease-in-out; }
      .offcanvas__burger i:hover {
        color: #1e2325; }
      @media screen and (max-width: 575px) {
        .offcanvas__burger i {
          font-size: 20px; } }
    .offcanvas__burger--text {
      margin-left: 0.5rem;
      opacity: 0;
      transition: 0.2s all ease-in-out; }
  .offcanvas__body {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5rem; }
    @media screen and (max-width: 960px) {
      .offcanvas__body {
        grid-template-columns: 1fr 1.5fr;
        gap: 1.5rem; } }
    @media screen and (max-width: 575px) {
      .offcanvas__body {
        grid-template-columns: 1fr; } }
  @media screen and (max-width: 575px) {
    .offcanvas__body--left {
      display: none; } }
  .offcanvas__body--right {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media screen and (max-width: 575px) {
      .offcanvas__body--right {
        padding-left: 1.5rem; } }
  .offcanvas__navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 7.5rem; }
  .offcanvas__menu {
    width: 100%;
    display: flex;
    justify-content: flex-start; }
  .offcanvas__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .offcanvas__nav li.menu-item {
      margin-bottom: 0;
      overflow: hidden; }
      .offcanvas__nav li.menu-item a {
        font-size: 3.45rem;
        text-transform: uppercase;
        text-decoration: none;
        color: #1e2325;
        line-height: 1; }
        .offcanvas__nav li.menu-item a:hover {
          color: #353e41; }
        @media screen and (max-width: 767px) {
          .offcanvas__nav li.menu-item a {
            font-size: 2.1875rem; } }
        @media screen and (max-width: 575px) {
          .offcanvas__nav li.menu-item a {
            font-size: 1.875rem; } }
  .offcanvas__nav-link {
    transform: translateY(56px); }
  .offcanvas__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 4rem;
    opacity: 0; }
  .offcanvas__contacto {
    font-size: 0.9375rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem; }
    .offcanvas__contacto .widget-contacto__title {
      font-size: 0.9375rem;
      text-transform: uppercase;
      margin-bottom: 0; }
    .offcanvas__contacto ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none; }
    .offcanvas__contacto a {
      text-transform: none;
      color: #424142; }
      .offcanvas__contacto a:hover {
        color: #424142; }
  .offcanvas__rs .zoom-social-icons-list {
    margin-left: 0 !important;
    display: flex;
    justify-content: center;
    gap: 0.5rem; }
  .offcanvas__rs .social-icon {
    font-size: 18px !important;
    padding: 0.5rem !important;
    background-color: #1e2325 !important;
    color: #d4d3cb !important;
    transition: 0.2s all ease-in-out; }
    @media screen and (max-width: 767px) {
      .offcanvas__rs .social-icon {
        font-size: 18px !important;
        transition: 0.2s all ease-in-out; } }
    .offcanvas__rs .social-icon:hover {
      background-color: #1e2325 !important;
      color: #d4d3cb !important;
      transition: 0.2s all ease-in-out;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px); }

.scolor__item {
  margin-right: 2rem;
  margin-bottom: 2rem; }
  .scolor__item div {
    width: 100px;
    height: 100px;
    border-radius: 0.3rem;
    border: 1px solid #b6b6b6; }

.scolor small, .scolor .small {
  font-size: 0.7rem !important; }

.sespacio {
  display: flex;
  justify-content: center;
  align-items: center; }

.page-title {
  padding: 13.4375rem 0 4rem 0; }
  @media screen and (max-width: 767px) {
    .page-title {
      padding-top: 7.5rem; } }
  @media screen and (max-width: 575px) {
    .page-title {
      padding-top: 6rem;
      padding-bottom: 2.5rem; } }
  .page-title--product {
    padding: 7.5rem 0 4rem 0; }
    @media screen and (max-width: 767px) {
      .page-title--product {
        padding: 5rem 0 2.5rem 0; } }
    @media screen and (max-width: 575px) {
      .page-title--product {
        padding-top: 4rem 0 1.5rem 0; } }
  .page-title__container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .page-title__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Prata", serif;
    color: #1e0028;
    text-align: center;
    max-width: 75%; }
  .page-title__title-cont {
    overflow: hidden; }
  .page-title__title {
    font-size: 96px;
    opacity: 0;
    transform: translateY(230px);
    text-transform: uppercase;
    padding-top: 1rem; }
    @media screen and (max-width: 991px) {
      .page-title__title {
        font-size: 4.35rem; } }
    @media screen and (max-width: 767px) {
      .page-title__title {
        font-size: 3.45rem; } }
    @media screen and (max-width: 480px) {
      .page-title__title {
        font-size: 2.5rem; } }
    .page-title__title--sm {
      font-size: 4.35rem; }
      @media screen and (max-width: 991px) {
        .page-title__title--sm {
          font-size: 3.45rem; } }
      @media screen and (max-width: 767px) {
        .page-title__title--sm {
          font-size: 2.18rem; } }
  .page-title__subtitle {
    text-align: center;
    color: #424142;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase; }
    @media screen and (max-width: 575px) {
      .page-title__subtitle {
        font-size: 1.25rem; } }
    @media screen and (max-width: 480px) {
      .page-title__subtitle {
        font-size: 1.125rem; } }
  .page-title__breadcrumbs {
    margin-bottom: 0.5rem;
    font-size: 0.9375rem;
    color: #424142; }
    @media screen and (max-width: 575px) {
      .page-title__breadcrumbs {
        margin-bottom: 0px;
        font-size: 0.75rem; } }
    .page-title__breadcrumbs a {
      font-weight: 500;
      color: #1e0028;
      text-decoration: none;
      font-weight: 600; }
    @media screen and (min-width: 992px) {
      .page-title__breadcrumbs > span > span > a {
        margin-right: 0.3rem; }
      .page-title__breadcrumbs > span > span > span a {
        margin-left: 0.3rem;
        margin-right: 0.3rem; }
      .page-title__breadcrumbs .breadcrumb_last {
        margin-left: 0.3rem; } }

.boton {
  padding: 0.75rem 1.475rem;
  font-size: 1rem;
  border-radius: 50px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  text-transform: uppercase; }
  .boton--lg {
    padding: 1.125rem 1.9375rem; }
    @media screen and (max-width: 767px) {
      .boton--lg {
        padding: 1rem 1.475rem; } }
  .boton--sm {
    padding: 0.625rem 1.25em;
    font-size: 0.9375rem; }

.boton--primary {
  background: #886d50;
  color: #fff;
  border-color: #886d50;
  transition: 0.2s all ease-in-out; }
  .boton--primary:hover {
    background: #786047;
    border-color: #786047;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--primary-outline {
  color: #424142;
  border-color: #886d50;
  transition: 0.2s all ease-in-out; }
  .boton--primary-outline:hover {
    background: #786047;
    border-color: #786047;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--secondary {
  background: #ba042c;
  color: #fff;
  border-color: #ba042c;
  transition: 0.2s all ease-in-out; }
  .boton--secondary:hover {
    background: #a10326;
    border-color: #a10326;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--secondary-outline {
  color: #424142;
  border-color: #ba042c;
  transition: 0.2s all ease-in-out; }
  .boton--secondary-outline:hover {
    background: #a10326;
    border-color: #a10326;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--accent {
  background: #2cc374;
  color: #fff;
  border-color: #2cc374;
  transition: 0.2s all ease-in-out; }
  .boton--accent:hover {
    background: #27ae68;
    border-color: #27ae68;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--accent-outline {
  color: #2cc374;
  border-color: #2cc374;
  transition: 0.2s all ease-in-out; }
  .boton--accent-outline:hover {
    background: #27ae68;
    border-color: #27ae68;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--white {
  background: #fff;
  color: #424142;
  border-color: #fff;
  transition: 0.2s all ease-in-out; }
  .boton--white:hover {
    background: #f2f2f2;
    border-color: #f2f2f2;
    color: #424142;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--white-outline {
  color: #424142;
  border-color: #fff;
  transition: 0.2s all ease-in-out; }
  .boton--white-outline:hover {
    background: #f2f2f2;
    border-color: #f2f2f2;
    color: #424142;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--dark {
  background: #424142;
  color: #fff;
  border-color: #424142;
  transition: 0.2s all ease-in-out; }
  .boton--dark:hover {
    background: #353435;
    border-color: #353435;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--dark-outline {
  color: #7f7f7f;
  border-color: #424142;
  transition: 0.2s all ease-in-out; }
  .boton--dark-outline:hover {
    background: #353435;
    border-color: #353435;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--whatsapp {
  background: #128c7e;
  color: #fff;
  border-color: #128c7e;
  transition: 0.2s all ease-in-out; }
  .boton--whatsapp:hover {
    background: #0f756a;
    border-color: #0f756a;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--whatsapp-outline {
  color: #424142;
  border-color: #128c7e;
  transition: 0.2s all ease-in-out; }
  .boton--whatsapp-outline:hover {
    background: #0f756a;
    border-color: #0f756a;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--text {
  background: transparent;
  color: #1e0028;
  border-color: transparent;
  transition: 0.2s all ease-in-out; }
  .boton--text:hover {
    background: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    color: #1e0028;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--text-outline {
  color: #424142;
  border-color: transparent;
  transition: 0.2s all ease-in-out; }
  .boton--text-outline:hover {
    background: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    color: #1e0028;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--text:hover {
  box-shadow: none; }

.page__entry,
.post__entry {
  color: #424142;
  line-height: 1.7; }
  .page__entry p a,
  .page__entry ul a,
  .page__entry ol a,
  .page__entry blockquote a,
  .post__entry p a,
  .post__entry ul a,
  .post__entry ol a,
  .post__entry blockquote a {
    color: #83031f; }
  .page__entry h1, .page__entry .h1,
  .page__entry h2,
  .page__entry .h2,
  .page__entry h3,
  .page__entry .h3,
  .page__entry h4,
  .page__entry .h4,
  .page__entry h5,
  .page__entry .h5,
  .page__entry h6,
  .page__entry .h6,
  .post__entry h1,
  .post__entry .h1,
  .post__entry h2,
  .post__entry .h2,
  .post__entry h3,
  .post__entry .h3,
  .post__entry h4,
  .post__entry .h4,
  .post__entry h5,
  .post__entry .h5,
  .post__entry h6,
  .post__entry .h6 {
    line-height: 1.6;
    margin-bottom: 1.5rem; }
  
  .page__entry h2,
  .page__entry .h2,
  .post__entry h2,
  .post__entry .h2 {
    margin-top: 2.5rem; }
  .page__entry h2:nth-child(1), .page__entry .h2:nth-child(1),
  .post__entry h2:nth-child(1),
  .post__entry .h2:nth-child(1) {
    margin-top: 0; }
  .page__entry p,
  .page__entry ol,
  .page__entry ul,
  .post__entry p,
  .post__entry ol,
  .post__entry ul {
    font-size: 1.1rem; }
    @media print {
      .page__entry p,
      .page__entry ol,
      .page__entry ul,
      .post__entry p,
      .post__entry ol,
      .post__entry ul {
        font-size: 10pt; } }
  .page__entry p,
  .page__entry ol,
  .page__entry ul,
  .page__entry blockquote,
  .post__entry p,
  .post__entry ol,
  .post__entry ul,
  .post__entry blockquote {
    margin-bottom: 1.5rem; }
  .page__entry p:nth-last-child(1),
  .post__entry p:nth-last-child(1) {
    margin-bottom: 0; }
  .page__entry ul li,
  .post__entry ul li {
    margin-bottom: 10px; }
  
  .page__entry h2,
  .page__entry .h2,
  .post__entry h2,
  .post__entry .h2 {
    font-size: 2.2rem;
    color: #83031f !important;
    font-weight: 600; }
    @media screen and (max-width: 575px) {
      
      .page__entry h2,
      .page__entry .h2,
      .post__entry h2,
      .post__entry .h2 {
        font-size: 2rem;
        line-height: 1.3; } }
    .page__entry h2 strong, .page__entry .h2 strong,
    .post__entry h2 strong,
    .post__entry .h2 strong {
      font-weight: 600; }
  
  .page__entry h3,
  .page__entry .h3,
  .post__entry h3,
  .post__entry .h3 {
    font-size: 1.5rem;
    color: #83031f;
    font-weight: 600; }
    @media screen and (max-width: 575px) {
      
      .page__entry h3,
      .page__entry .h3,
      .post__entry h3,
      .post__entry .h3 {
        font-size: 1.4rem;
        line-height: 1.3; } }
  .page__entry strong,
  .post__entry strong {
    font-weight: 600; }
  .page__entry .wp-block-pullquote,
  .post__entry .wp-block-pullquote {
    padding: 2rem 0 !important;
    text-align: left !important;
    margin-bottom: 0 !important; }
  .page__entry .wp-block-image,
  .post__entry .wp-block-image {
    margin: 2rem 0; }
    @media print {
      .page__entry .wp-block-image,
      .post__entry .wp-block-image {
        width: 80%;
        margin-left: auto;
        margin-right: auto; } }
    .page__entry .wp-block-image img,
    .post__entry .wp-block-image img {
      width: 100%;
      height: auto;
      border-radius: 0.625rem !important;
      box-shadow: 0px 8px 16px rgba(30, 0, 40, 0.08) !important; }
    .page__entry .wp-block-image figcaption,
    .post__entry .wp-block-image figcaption {
      text-align: center;
      font-size: 0.75rem; }
  .page__entry .wp-block-gallery,
  .post__entry .wp-block-gallery {
    margin: 1.8rem 0; }
    .page__entry .wp-block-gallery .blocks-gallery-item,
    .post__entry .wp-block-gallery .blocks-gallery-item {
      margin-bottom: 0; }
    .page__entry .wp-block-gallery img,
    .post__entry .wp-block-gallery img {
      width: 100%;
      height: auto; }
    .page__entry .wp-block-gallery figcaption,
    .post__entry .wp-block-gallery figcaption {
      text-align: center; }
  .page__entry .block-wikiloc iframe,
  .post__entry .block-wikiloc iframe {
    width: 100%;
    margin-top: 2rem !important;
    margin-bottom: 2rem;
    box-shadow: 0px 8px 16px rgba(30, 0, 40, 0.08) !important;
    border-radius: 0.5rem; }
  .page__entry .block-wikiloc div,
  .post__entry .block-wikiloc div {
    display: none; }

.wpcf7 .form-group {
  margin-bottom: 1rem; }

.wpcf7 .form-label {
  font-weight: 500;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem !important; }

.wpcf7 .form-control {
  width: 100%;
  padding: 0.575rem 0.75rem;
  font-size: 0.85rem;
  background-color: #f8f3ed;
  border-radius: 0 !important;
  border: none;
  border-bottom: 1px solid #1e0028; }
  .wpcf7 .form-control::placeholder {
    color: rgba(0, 0, 0, 0.3); }

.wpcf7 .wpcf7-not-valid-tip {
  font-size: 12px;
  color: red; }

.wpcf7-acceptance {
  font-size: 0.6rem;
  color: #424142; }

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
  color: #fff; }

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: #f8f3ed; }

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: #f8f3ed; }

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500; }

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important; }

body:not(.page-template-page-contacto):not(.single-programas) .grecaptcha-badge {
  display: none; }

.grecaptcha-badge {
  bottom: 28px !important; }

.cmplz-manage-consent {
  right: 80px !important;
  background-color: #fff !important;
  color: #56432f !important;
  display: none; }

.cmplz-cookiebanner .cmplz-categories .cmplz-category {
  background-color: transparent !important; }
  .cmplz-cookiebanner .cmplz-categories .cmplz-category .cmplz-always-active {
    color: #56432f !important;
    font-weight: 400 !important; }
  .cmplz-cookiebanner .cmplz-categories .cmplz-category .cmplz-icon {
    display: flex; }
    .cmplz-cookiebanner .cmplz-categories .cmplz-category .cmplz-icon svg path {
      fill: #1e2325; }

.badge {
  padding: 3px 6px;
  border-radius: 4px; }
  .badge--primary {
    background-color: #886d50;
    color: #fff; }
  .badge--secondary {
    background-color: #ba042c;
    color: #fff; }
  .badge--light {
    background-color: #fff;
    color: #1e2325; }
  .badge--dark {
    background-color: #1e2325;
    color: #fff; }
  .badge--accent {
    background-color: #2cc374;
    color: #fff; }
  .badge--outline-primary {
    background-color: #fff;
    border: 1px solid #886d50;
    color: #fff; }
  .badge--outline-secondary {
    background-color: #fff;
    border: 1px solid #ba042c;
    color: #fff; }
  .badge--outline-light {
    background-color: #fff;
    border: 1px solid #fff;
    color: #1e2325; }
  .badge--outline-dark {
    background-color: #fff;
    border: 1px solid #1e2325;
    color: #fff; }
  .badge--outline-accent {
    background-color: #fff;
    border: 1px solid #2cc374;
    color: #fff; }
  .badge--rounded {
    border-radius: 50px; }
  .badge--bold {
    font-weight: 600; }
  .badge--sm {
    padding: 2px 7px;
    font-size: 0.9375rem; }
  .badge--ico {
    display: flex;
    align-items: center; }
    .badge--ico img,
    .badge--ico svg {
      margin-right: 5px; }

.woof_list_checkbox {
  padding-left: 0; }
  .woof_list_checkbox > .woof_childs_list_li,
  .woof_list_checkbox > li > li,
  .woof_list_checkbox > li {
    display: block;
    margin-bottom: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1); }
  .woof_list_checkbox .woof_childs_list {
    padding-left: 0.5rem; }
  .woof_list_checkbox li .woof_checkbox_label {
    padding-top: 3px;
    padding-bottom: 3px;
    display: inline-block;
    width: 70%;
    font-family: "Prata", serif; }
  .woof_list_checkbox .woof_checkbox_count {
    font-size: 80% !important; }
  .woof_list_checkbox .woof_childs_list {
    align-self: flex-end; }

.woof_container {
  margin-bottom: 2rem !important; }
  .woof_container .woof_container_inner > h4, .woof_container .woof_container_inner > .h4 {
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: bold; }

.archive .woof_list_checkbox > li {
  display: block;
  margin-bottom: 0.4rem !important;
  padding-bottom: 0.4rem !important;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1); }

.woof_products_top_panel {
  line-height: initial !important; }
  .woof_products_top_panel .woof_products_top_panel_ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    @media screen and (max-width: 767px) {
      .woof_products_top_panel .woof_products_top_panel_ul {
        flex-direction: column;
        align-items: flex-start; } }
    .woof_products_top_panel .woof_products_top_panel_ul li ul {
      display: flex;
      align-items: center;
      margin-bottom: 5px; }
      .woof_products_top_panel .woof_products_top_panel_ul li ul li a {
        background-color: #d7d7d7;
        padding: 3px 10px;
        border-radius: 50px;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        display: inline-flex; }
  .woof_products_top_panel .woof_reset_button_2 {
    padding: 5px 1rem !important;
    border-radius: 3px;
    background-color: #b6b6b6;
    border: none;
    color: #f8f3ed;
    height: auto; }

.woof_block_html_items .chosen-single {
  height: 35px !important;
  background: none !important;
  box-sizing: content-box !important; }

.vi_wpvs_loop_variation_form .vi-wpvs-option-wrap {
  padding: 5px 10px !important;
  font-size: 0.8rem !important; }

.vi_wpvs_loop_variation_form .vi-wpvs-variation-attribute-type-button {
  margin-bottom: 0; }

.cta {
  padding: 13.4375rem 0 7.5rem 0; }
  @media screen and (max-width: 767px) {
    .cta {
      padding-top: 7.5rem; } }
  @media screen and (max-width: 575px) {
    .cta {
      padding-top: 6rem;
      padding-bottom: 2.5rem; } }
  .cta__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    text-align: center; }
    @media screen and (max-width: 600px) {
      .cta__content {
        max-width: calc(100vw - 10%); } }
  .cta__title-container {
    overflow: hidden; }
  .cta__title {
    transform: translateY(170px);
    font-size: 3.45rem;
    font-family: "Prata", serif;
    text-transform: uppercase;
    color: #1e0028;
    line-height: 1.3;
    margin-bottom: 1rem;
    opacity: 0; }
    @media screen and (max-width: 767px) {
      .cta__title {
        font-size: 2.18rem; } }
    @media screen and (max-width: 575px) {
      .cta__title {
        font-size: 1.875rem; } }

.feat__item {
  display: flex;
  margin-bottom: 7.5rem; }
  @media screen and (max-width: 991px) {
    .feat__item {
      margin-bottom: 2.5rem; } }
  .feat__item:nth-last-child(1) {
    margin-bottom: 0; }

.feat__card {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)); }
  @media screen and (min-width: 1400px) {
    .feat__card {
      grid-template-columns: 1.5fr 1fr; } }
  @media screen and (max-width: 991px) {
    .feat__card {
      grid-template-columns: 1fr; } }
  @media screen and (max-width: 767px) {
    .feat__card {
      margin-bottom: 3rem; } }
  .feat__card--11 {
    grid-template-columns: 1fr 1.5fr; }
    @media screen and (min-width: 1400px) {
      .feat__card--11 {
        grid-template-columns: 1fr 1.5fr; } }
  @media screen and (min-width: 1400px) {
    .feat__card--inverted {
      grid-template-columns: 1fr 1.5fr; } }
  .feat__card--inverted.feat__card--11 {
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .feat__card--inverted .feat__card-picture {
    order: 2; }
    @media screen and (max-width: 991px) {
      .feat__card--inverted .feat__card-picture {
        order: 1; } }
  .feat__card--inverted .feat__card-content {
    order: 1;
    padding: 0 4rem 0 0; }
    @media screen and (max-width: 991px) {
      .feat__card--inverted .feat__card-content {
        padding: 1.5rem 0;
        order: 2; } }
  @media screen and (max-width: 991px) and (max-width: 767px) {
    .feat__card--inverted .feat__card-content {
      padding: 0.2rem 0; } }

.feat__card-picture {
  position: relative; }
  @media screen and (max-width: 767px) {
    .feat__card-picture {
      margin-bottom: 0.5rem; } }
  .feat__card-picture img {
    position: relative;
    aspect-ratio: 16 / 10;
    width: 100%;
    height: auto;
    object-fit: cover; }
    @media screen and (max-width: 1280px) {
      .feat__card-picture img {
        aspect-ratio: 16 / 12; } }
    @media screen and (max-width: 991px) {
      .feat__card-picture img {
        aspect-ratio: 16 / 11; } }

.feat__card-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 4rem; }
  @media screen and (max-width: 991px) {
    .feat__card-content {
      padding: 1.5rem 0; } }
  @media screen and (max-width: 767px) {
    .feat__card-content {
      padding: 0.2rem 0; } }
  .feat__card-content--center {
    align-items: center;
    text-align: center; }

.feat__card-title {
  font-family: "Prata", serif;
  font-size: 2.1875rem;
  font-weight: 600;
  line-height: 1.4;
  color: #ba042c;
  letter-spacing: -1px;
  margin-bottom: 1rem; }
  @media screen and (max-width: 1440px) {
    .feat__card-title {
      font-size: 2rem; } }
  @media screen and (max-width: 991px) {
    .feat__card-title {
      font-size: 1.6rem;
      margin-bottom: 1rem; } }
  @media screen and (max-width: 575px) {
    .feat__card-title {
      font-size: 1.5rem; } }

.feat__card-description {
  color: #fff;
  line-height: 1.5; }
  .feat__card-description ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
    .feat__card-description ul li {
      position: relative;
      padding-left: 1.5rem; }
      .feat__card-description ul li::before {
        position: absolute;
        left: 0;
        top: 3px;
        display: inline-block;
        content: "";
        color: #fff;
        vertical-align: -0.125em;
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z' clip-rule='evenodd'/></svg>");
        background-repeat: no-repeat;
        background-size: 1rem 1rem;
        width: 1rem;
        height: 1rem;
        filter: brightness(0) invert(1); }

.hero-v {
  margin-top: 5rem;
  height: calc(100vh - 7rem);
  margin-right: 2rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 575px) {
    .hero-v {
      margin-top: 4rem;
      height: calc(100vh - 5rem);
      margin-right: 1rem;
      margin-bottom: 1rem;
      margin-left: 1rem; } }
  .hero-v__video {
    position: relative;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.plazos__head {
  margin-bottom: 1rem; }

.plazos__item {
  margin-bottom: 0.75rem;
  border-bottom: 1px dashed #1e2325; }

.plazos__item-titulo {
  font-weight: 600; }

.plazos__item-descripcion {
  margin-bottom: 0.25rem; }

.titulos__titulo--home1, .titulos__titulo--home-proyectos {
  transform: translateY(90px);
  opacity: 0; }

.h-tienda {
  padding: 7.5rem 0; }
  @media screen and (max-width: 767px) {
    .h-tienda {
      padding: 5rem 0; } }
  @media screen and (max-width: 575px) {
    .h-tienda {
      padding: 4rem 0; } }
  .h-tienda__productos {
    max-width: calc(100vw - 10%);
    margin-right: 5%;
    margin-left: 5%; }

.loop-tienda {
  max-width: calc(100vw - 10%);
  margin-right: 5%;
  margin-left: 5%; }

.img-full {
  padding: 5rem 0; }
  .img-full__container {
    max-width: calc(100vw - 24%);
    margin-right: 12%;
    margin-left: 12%;
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 991px) {
      .img-full__container {
        max-width: calc(100vw - 8%);
        margin-right: 4%;
        margin-left: 4%; } }
  .img-full__img {
    width: 100%;
    height: auto;
    scale: 1.2 !important;
    will-change: transform; }

.contacto {
  background-color: #f8f3ed; }
  .contacto__content {
    color: #424142;
    display: grid;
    grid-template-columns: 1fr 2fr; }
    @media screen and (max-width: 991px) {
      .contacto__content {
        display: flex;
        flex-direction: column; } }
  @media screen and (max-width: 991px) {
    .contacto__col1 {
      margin-bottom: 1.5rem; } }
  .contacto__col2 {
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    color: #424142; }
    @media screen and (max-width: 991px) {
      .contacto__col2 {
        padding-left: 0; } }
  .contacto__descripcion {
    margin-bottom: 2rem;
    line-height: 1.8; }
  .contacto__telefonos {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    font-size: 1rem; }
    .contacto__telefonos a {
      text-decoration: none; }
  .contacto__telefonos-item {
    display: flex;
    margin-bottom: 0.2rem; }
    .contacto__telefonos-item .bi {
      margin-right: 0.5rem; }
    .contacto__telefonos-item:nth-last-child(1) {
      margin-top: 0.25rem;
      margin-bottom: 0; }
  .contacto__social .zoom-social-icons-list {
    margin-left: 0 !important;
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem; }
  .contacto__social .social-icon {
    font-size: 24px !important;
    padding: 0.5rem !important;
    background-color: #1e2325 !important;
    color: #f8f3ed !important;
    transition: 0.2s all ease-in-out; }
    @media screen and (max-width: 767px) {
      .contacto__social .social-icon {
        font-size: 24px !important;
        transition: 0.2s all ease-in-out; } }
    .contacto__social .social-icon:hover {
      background-color: #1e2325 !important;
      color: #f8f3ed !important;
      transition: 0.2s all ease-in-out;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px); }

.ubicacion {
  background-color: #f8f3ed; }
  .ubicacion iframe {
    width: 100%;
    height: 550px; }
    @media screen and (max-width: 767px) {
      .ubicacion iframe {
        height: 500px; } }
    @media screen and (max-width: 575px) {
      .ubicacion iframe {
        height: 400px; } }
    @media screen and (max-width: 480px) {
      .ubicacion iframe {
        height: 320px; } }

.legal {
  padding-top: 2.5rem;
  padding-bottom: 4rem;
  background-color: #f8f3ed; }

.h-proyectos {
  padding: 7.5rem 0 4rem 0;
  margin-bottom: 40rem; }
  .h-proyectos__grid {
    gap: 13.4375rem 2.5rem;
    display: flex;
    flex-wrap: wrap; }
    @media screen and (max-width: 767px) {
      .h-proyectos__grid {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 5rem;
        width: calc(100vw - 10%);
        margin-right: 5%;
        margin-left: 5%; } }

.p-proyectos__grid {
  gap: 13.4375rem 2.5rem;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 767px) {
    .p-proyectos__grid {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 5rem;
      width: calc(100vw - 10%);
      margin-right: 5%;
      margin-left: 5%; } }

.thumb-proyecto {
  flex: 1 1 43vw;
  padding-left: 7vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  color: #1e0028;
  transition: 0.2s all ease-in-out; }
  .thumb-proyecto:hover {
    color: #1e0028; }
    .thumb-proyecto:hover .thumb-proyecto__image img {
      scale: 1.1;
      transition: 0.3s all ease-in-out; }
  @media screen and (max-width: 767px) {
    .thumb-proyecto {
      flex: 1 1 100%;
      padding-left: 0;
      gap: 0; } }
  .thumb-proyecto:nth-last-child(1) {
    max-width: 50vw; }
    @media screen and (max-width: 767px) {
      .thumb-proyecto:nth-last-child(1) {
        max-width: 100vw; } }
  .thumb-proyecto__image {
    aspect-ratio: 0.843;
    width: 100%;
    height: auto;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      .thumb-proyecto__image {
        margin-bottom: 1rem; } }
    .thumb-proyecto__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s all ease-in-out; }
  .thumb-proyecto__title {
    font-family: "Prata", serif;
    font-size: 2.1875rem;
    text-transform: uppercase; }
    @media screen and (max-width: 767px) {
      .thumb-proyecto__title {
        font-size: 1.875rem; } }
    @media screen and (max-width: 480px) {
      .thumb-proyecto__title {
        font-size: 1.5rem; } }
  .thumb-proyecto__link {
    font-size: 1.5rem; }
    @media screen and (max-width: 767px) {
      .thumb-proyecto__link {
        font-size: 1.25rem; } }
    @media screen and (max-width: 480px) {
      .thumb-proyecto__link {
        font-size: 1.25rem; } }

.s-proyecto {
  padding-bottom: 13.4375rem; }
  .s-proyecto__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5rem; }
    @media screen and (max-width: 991px) {
      .s-proyecto__content {
        grid-template-columns: 1fr;
        gap: 4rem; } }
  .s-proyecto__datos {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media screen and (max-width: 991px) {
      .s-proyecto__datos {
        order: 2; } }
  .s-proyecto__title {
    font-size: 2.1875rem; }
  .s-proyecto__summary {
    margin-bottom: 2.5rem; }
  .s-proyecto__info {
    width: 100%; }
  .s-proyecto__info-item {
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 0;
    border-bottom: 1px solid #7f7f7f;
    color: #1e0028; }
    .s-proyecto__info-item:nth-child(1) {
      border-top: 1px solid #7f7f7f; }
  .s-proyecto__info-description {
    margin-left: 0.25rem; }
  .s-proyecto__picture {
    align-self: center; }
    .s-proyecto__picture img {
      width: 100%;
      height: auto;
      object-fit: cover; }
    @media screen and (max-width: 991px) {
      .s-proyecto__picture {
        order: 1; } }

.p-galeria {
  padding-bottom: 13.4375rem; }
  .p-galeria__img {
    width: 100%;
    height: auto;
    padding: 0 0.5rem; }

.slick-dots button::before {
  font-size: 12px !important; }

.navegador-proyectos {
  display: grid;
  grid-template-columns: 15% 70% 15%;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "thumb-prev content-next thumb-next" "thumb-prev content-prev thumb-next";
  border-top: 1px solid #1e0028;
  border-bottom: 1px solid #1e0028; }
  .navegador-proyectos__content {
    width: 100%;
    justify-self: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase; }
    .navegador-proyectos__content a {
      display: flex;
      align-items: center;
      color: #1e0028; }
    .navegador-proyectos__content span {
      color: #b6b6b6; }
    .navegador-proyectos__content--next {
      font-size: 2.1875rem;
      grid-area: content-next; }
      @media screen and (max-width: 991px) {
        .navegador-proyectos__content--next {
          font-size: 1.5rem; } }
      @media screen and (max-width: 767px) {
        .navegador-proyectos__content--next {
          font-size: 1.25rem; } }
      .navegador-proyectos__content--next a {
        align-self: flex-end; }
    .navegador-proyectos__content--prev {
      grid-area: content-prev; }
      .navegador-proyectos__content--prev a {
        align-self: flex-start; }
  .navegador-proyectos__thumbnail {
    display: block;
    margin: 0;
    width: 100%; }
    .navegador-proyectos__thumbnail img {
      width: 100%;
      height: 180px;
      object-fit: cover; }
    .navegador-proyectos__thumbnail--next {
      grid-area: thumb-next;
      justify-self: flex-end; }
    .navegador-proyectos__thumbnail--prev {
      grid-area: thumb-prev; }

.yith-wapo-addon .options.per-row-6 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  gap: 1rem 0.6rem; }
  .yith-wapo-addon .options.per-row-6 .yith-wapo-option {
    max-width: 100% !important; }
  .yith-wapo-addon .options.per-row-6 .clear {
    display: none; }

.yith-wapo-addon .options.per-row-5 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(42px, 1fr));
  gap: 1rem 0.5rem; }
  .yith-wapo-addon .options.per-row-5 .yith-wapo-option {
    max-width: 100% !important; }
  .yith-wapo-addon .options.per-row-5 .clear {
    display: none; }

.yith-wapo-addon .options.per-row-4 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
  gap: 1rem 0.5rem; }
  .yith-wapo-addon .options.per-row-4 .yith-wapo-option {
    max-width: 100% !important; }
  .yith-wapo-addon .options.per-row-4 .clear {
    display: none; }

.yith-wapo-addon .options.per-row-3 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
  gap: 1rem 0.5rem; }
  .yith-wapo-addon .options.per-row-3 .yith-wapo-option {
    max-width: 100% !important; }
  .yith-wapo-addon .options.per-row-3 .clear {
    display: none; }

.yith-wapo-addon .options.per-row-2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
  gap: 1rem 0.5rem; }
  .yith-wapo-addon .options.per-row-2 .yith-wapo-option {
    max-width: 100% !important; }
  .yith-wapo-addon .options.per-row-2 .clear {
    display: none; }

.yith-wapo-addon .options.per-row-1 .yith-wapo-option {
  width: 100% !important;
  max-width: 100% !important; }

.wapo-addon-title {
  width: 100%;
  margin-right: 1rem;
  font-size: 1rem !important;
  color: #1e0028;
  font-weight: bold;
  letter-spacing: 1px; }

.yith-wapo-option {
  margin: 0 !important; }
  .yith-wapo-option .image img {
    width: 100%;
    height: auto;
    border-radius: 4px; }
  .yith-wapo-option label,
  .yith-wapo-option .label {
    display: none; }
  .yith-wapo-option label {
    min-width: 44px !important; }
  .yith-wapo-option label:hover {
    border-color: #1e2325 !important; }
  .yith-wapo-option input[type="text"] {
    padding: 8px 6px !important;
    border-radius: 4px !important; }

.single-product #wapo-total-price-table .wapo-product-price {
  display: none; }

.single-product #yith-wapo-addon-6 .label_price,
.single-product #yith-wapo-addon-16 .label_price,
.single-product #yith-wapo-addon-20 .label_price,
.single-product #yith-wapo-addon-27 .label_price,
.single-product #yith-wapo-addon-28 .label_price,
.single-product #yith-wapo-addon-32 .label_price,
.single-product #yith-wapo-addon-33 .label_price,
.single-product #yith-wapo-addon-34 .label_price,
.single-product #yith-wapo-addon-35 .label_price,
.single-product #yith-wapo-addon-36 .label_price,
.single-product #yith-wapo-addon-43 .label_price,
.single-product #yith-wapo-addon-44 .label_price,
.single-product #yith-wapo-addon-45 .label_price,
.single-product #yith-wapo-addon-46 .label_price,
.single-product #yith-wapo-addon-47 .label_price,
.single-product #yith-wapo-addon-48 .label_price,
.single-product #yith-wapo-addon-49 .label_price,
.single-product #yith-wapo-addon-50 .label_price,
.single-product #yith-wapo-addon-53 .label_price,
.single-product #yith-wapo-addon-58 .label_price,
.single-product #yith-wapo-addon-59 .label_price,
.single-product #yith-wapo-addon-61 .label_price,
.single-product #yith-wapo-addon-65 .label_price,
.single-product #yith-wapo-addon-67 .label_price,
.single-product #yith-wapo-addon-68 .label_price,
.single-product #yith-wapo-addon-69 .label_price,
.single-product #yith-wapo-addon-70 .label_price,
.single-product #yith-wapo-addon-72 .label_price,
.single-product #yith-wapo-addon-76 .label_price,
.single-product #yith-wapo-addon-81 .label_price,
.single-product #yith-wapo-addon-82 .label_price,
.single-product #yith-wapo-addon-83 .label_price,
.single-product #yith-wapo-addon-84 .label_price,
.single-product #yith-wapo-addon-85 .label_price,
.single-product #yith-wapo-addon-86 .label_price,
.single-product #yith-wapo-addon-87 .label_price,
.single-product #yith-wapo-addon-88 .label_price,
.single-product #yith-wapo-addon-92 .label_price,
.single-product #yith-wapo-addon-96 .label_price,
.single-product #yith-wapo-addon-97 .label_price,
.single-product #yith-wapo-addon-98 .label_price,
.single-product #yith-wapo-addon-99 .label_price,
.single-product #yith-wapo-addon-100 .label_price,
.single-product #yith-wapo-addon-101 .label_price,
.single-product #yith-wapo-addon-105 .label_price,
.single-product #yith-wapo-addon-109 .label_price,
.single-product #yith-wapo-addon-110 .label_price,
.single-product #yith-wapo-addon-111 .label_price,
.single-product #yith-wapo-addon-112 .label_price,
.single-product #yith-wapo-addon-113 .label_price,
.single-product #yith-wapo-addon-114 .label_price,
.single-product #yith-wapo-addon-118 .label_price,
.single-product #yith-wapo-addon-122 .label_price,
.single-product #yith-wapo-addon-123 .label_price,
.single-product #yith-wapo-addon-124 .label_price,
.single-product #yith-wapo-addon-125 .label_price,
.single-product #yith-wapo-addon-126 .label_price,
.single-product #yith-wapo-addon-127 .label_price,
.single-product #yith-wapo-addon-131 .label_price,
.single-product #yith-wapo-addon-135 .label_price,
.single-product #yith-wapo-addon-136 .label_price,
.single-product #yith-wapo-addon-137 .label_price,
.single-product #yith-wapo-addon-138 .label_price,
.single-product #yith-wapo-addon-139 .label_price,
.single-product #yith-wapo-addon-140 .label_price,
.single-product #yith-wapo-addon-144 .label_price,
.single-product #yith-wapo-addon-148 .label_price,
.single-product #yith-wapo-addon-149 .label_price,
.single-product #yith-wapo-addon-150 .label_price,
.single-product #yith-wapo-addon-151 .label_price,
.single-product #yith-wapo-addon-152 .label_price,
.single-product #yith-wapo-addon-153 .label_price,
.single-product #yith-wapo-addon-157 .label_price {
  display: block;
  text-decoration: none;
  line-height: var(--yith-wccl-select-option-size, 40px);
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  min-width: var(--yith-wccl-select-option-size, 40px);
  height: var(--yith-wccl-select-option-size, 40px);
  border-radius: 4px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-color: #1e2325;
  background-color: #1e2325;
  color: #f8f3ed; }

.yith_wccl_is_custom .select_option_label {
  border-radius: 4px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin: 0 !important;
  border-color: #1e2325;
  background-color: #1e2325;
  color: #f8f3ed; }

.variations {
  width: 100%;
  margin-bottom: 20px; }
  .variations tr {
    display: flex;
    flex-direction: column; }
  .variations th.label label {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem !important;
    text-transform: none !important; }
  .variations .select_box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    gap: 1rem 0.5rem; }
  .variations .select_box_image {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(44px, 1fr));
    gap: 1rem 0.5rem; }
    .variations .select_box_image .select_option_image {
      max-width: 44px;
      border-radius: 4px;
      margin: 0;
      border: 2px solid #7a7a7a; }
      .variations .select_box_image .select_option_image img {
        border-radius: 2px; }

.single-product.woocommerce .woocommerce-variation-add-to-cart {
  display: grid !important;
  grid-template-columns: auto 1fr;
  gap: 1.5rem; }

.yith-wapo-container {
  grid-column: 1 / span 2; }

.eg2-blog {
  padding: 4rem 0; }
  @media screen and (max-width: 991px) {
    .eg2-blog {
      padding: 2.5rem 0; } }
  .eg2-blog__content {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 3rem 1.875rem; }
    @media screen and (max-width: 991px) {
      .eg2-blog__content {
        grid-template-columns: repeat(4, minmax(0, 1fr)); } }
    @media screen and (max-width: 575px) {
      .eg2-blog__content {
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }

.thumb-blog {
  grid-column: span 2;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  text-align: center; }
  .thumb-blog__thumbnail img {
    aspect-ratio: 1.625;
    width: 100%;
    height: auto;
    object-fit: cover; }
  .thumb-blog__categoria {
    color: #83031f;
    text-transform: uppercase; }
  .thumb-blog__titulo a {
    text-decoration: none;
    font-size: 1.5rem;
    color: #1e2325;
    font-family: "Prata", serif; }

.entrada {
  padding: 1.5rem 0 0 0; }
  .entrada__container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 70px 1fr 70px;
    gap: 2.5rem; }
    @media screen and (max-width: 991px) {
      .entrada__container {
        grid-template-columns: 40px 1fr; } }
    @media screen and (max-width: 575px) {
      .entrada__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start; } }
  .entrada__thumbnail {
    margin-bottom: 1rem;
    overflow: hidden; }
    .entrada__thumbnail img {
      width: 100%;
      height: auto;
      scale: 1.2 !important;
      will-change: transform; }
  .entrada .post__cat {
    margin-top: 0; }

.share-post {
  position: sticky;
  top: 5rem;
  margin-top: 0.75rem; }
  @media screen and (max-width: 991px) {
    .share-post {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0; } }
  @media screen and (max-width: 575px) {
    .share-post {
      flex-direction: row;
      align-items: center; } }
  .share-post__item {
    margin-bottom: 1rem; }
    @media screen and (max-width: 991px) {
      .share-post__item {
        margin-right: 0.75rem;
        margin-bottom: 0; } }
    .share-post__item a:hover svg {
      transform: translateY(-2px);
      transition: 0.2s all ease-in-out; }
  .share-post svg {
    transition: 0.2s all ease-in-out;
    width: 42px;
    height: auto; }
    .share-post svg path:first-child {
      fill: #1e2325 !important; }

.relacionadas {
  padding: 4rem 0;
  background-color: #f5f5f5; }
  .relacionadas__content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 3rem 1.875rem; }
    @media screen and (max-width: 991px) {
      .relacionadas__content {
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }
    @media screen and (max-width: 575px) {
      .relacionadas__content {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }

.galeria__content {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-template-rows: auto;
  row-gap: 9.5rem; }
  @media screen and (max-width: 767px) {
    .galeria__content {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 2px; } }

@media screen and (min-width: 768px) {
  .galeria__item:nth-child(1) {
    grid-column: 2 / span 6;
    grid-row: 1; }
  .galeria__item:nth-child(2) {
    grid-column: 1 / span 5;
    grid-row: 2 / span 2; }
  .galeria__item:nth-child(3) {
    grid-column: 7 / span 2;
    grid-row: 2 / span 1; }
  .galeria__item:nth-child(4) {
    grid-column: 3 / span 4;
    grid-row: 4 / span 2; }
  .galeria__item:nth-child(5) {
    grid-column: 1 / span 3;
    grid-row: 6 / span 1; }
  .galeria__item:nth-child(6) {
    grid-column: 5 / span 4;
    grid-row: 6 / span 1; }
  .galeria__item:nth-child(7) {
    grid-column: 3 / span 4;
    grid-row: 7 / span 2; } }

.galeria__item--video {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto; }

.galeria__img {
  width: 100%;
  height: auto; }

.inspiracion .ff-stream {
  padding: 0 !important;
  min-height: 100% !important;
  background-color: transparent !important; }

.inspiracion .ff-stream-wrapper {
  margin: 0 !important;
  padding: 0 !important; }

.inspiracion .ff-item {
  box-shadow: 0px 8px 16px rgba(30, 0, 40, 0.08) !important; }

.inspiracion .ff-loadmore-wrapper {
  visibility: hidden !important;
  display: none; }

.ff-slideshow .ff-supports-comments .ff-comments-list {
  display: none !important; }

#submit_bizum_payment_form,
#submit_redsys_payment_form {
  font-size: 0.9rem;
  background-color: transparent;
  color: #83031f !important;
  border-color: transparent !important;
  margin-right: 1rem;
  box-shadow: none;
  width: auto !important;
  padding: 0.75rem 1.475rem;
  font-size: 1rem;
  border-radius: 50px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  text-transform: uppercase;
  display: inline-flex !important; }
  #submit_bizum_payment_form--lg,
  #submit_redsys_payment_form--lg {
    padding: 1.125rem 1.9375rem; }
    @media screen and (max-width: 767px) {
      #submit_bizum_payment_form--lg,
      #submit_redsys_payment_form--lg {
        padding: 1rem 1.475rem; } }
  #submit_bizum_payment_form--sm,
  #submit_redsys_payment_form--sm {
    padding: 0.625rem 1.25em;
    font-size: 0.9375rem; }
  #submit_bizum_payment_form:hover,
  #submit_redsys_payment_form:hover {
    background-color: transparent;
    color: #83031f !important;
    border-color: transparent !important;
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.button.cancel {
  padding: 0.75rem 1.475rem;
  font-size: 1rem;
  border-radius: 50px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  text-transform: uppercase;
  background-color: #b6b6b6;
  border-color: #b6b6b6 !important;
  color: #83031f !important; }
  .button.cancel--lg {
    padding: 1.125rem 1.9375rem; }
    @media screen and (max-width: 767px) {
      .button.cancel--lg {
        padding: 1rem 1.475rem; } }
  .button.cancel--sm {
    padding: 0.625rem 1.25em;
    font-size: 0.9375rem; }
  .button.cancel:hover {
    background-color: #b6b6b6;
    border-color: #b6b6b6 !important;
    color: #83031f !important;
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.submit,
.button {
  font-size: 0.9rem;
  border-radius: 4px !important;
  background-color: #1e2325 !important;
  color: #fff !important;
  border-color: #1e2325 !important;
  display: inline;
  box-shadow: none;
  transition: 0.2s all ease-in-out;
  padding: 10px 15px !important; }
  .submit:hover,
  .button:hover {
    border-radius: 4px;
    background-color: #1e2325;
    color: #fff !important;
    border-color: #1e2325 !important;
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.single_add_to_cart_button {
  padding: 10px 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto;
  font-size: 1rem !important;
  letter-spacing: 0;
  margin-top: 1rem; }
  @media (max-width: 575px) {
    .single_add_to_cart_button {
      font-size: 0.9rem !important;
      padding: 0.8rem 3rem !important; } }
  @media (max-width: 575px) {
    .single_add_to_cart_button::before {
      font-size: 1rem;
      margin-right: 0.6rem; } }

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important; }

@media screen and (max-width: 357px) {
  form.cart {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; } }

.single_add_to_cart_button.disabled {
  background-color: none;
  opacity: 0.5;
  cursor: not-allowed; }
  .single_add_to_cart_button.disabled:hover {
    background-color: none !important; }

.checkout-button,
.single_add_to_cart_button {
  background-color: #1e2325;
  color: #fff !important;
  border-color: #1e2325 !important;
  font-weight: 700;
  border-radius: 4px; }
  .checkout-button:hover,
  .single_add_to_cart_button:hover {
    background-color: color-dark;
    color: #fff !important;
    border-color: #1e2325 !important; }

.button[aria-disabled="false"] {
  border: 0; }

.button[aria-disabled="true"] {
  background-color: transparent;
  color: #b6b6b6 !important;
  border: 0;
  cursor: not-allowed; }

.page-id-17 .woocommerce {
  display: grid;
  grid-template-columns: 3.3fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0; }
  @media (max-width: 991px) {
    .page-id-17 .woocommerce {
      display: block; } }
  .page-id-17 .woocommerce .woocommerce-notices-wrapper {
    grid-column: 1 / 3; }

.cart_totals,
.shop_table {
  width: 100%; }

/* Tabla del carrito de la compra CELDAS INDIVIDUALES */
.woocommerce-cart-form {
  margin-bottom: 2rem; }
  .woocommerce-cart-form .shop_table {
    width: 100%;
    box-shadow: none !important; }
  .woocommerce-cart-form .product-remove {
    width: 40px;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 767px) {
      .woocommerce-cart-form .product-remove::before {
        display: none; } }
  .woocommerce-cart-form .product-remove a {
    font-size: 1.5rem;
    color: #83031f !important; }
  .woocommerce-cart-form .product-thumbnail a {
    display: flex; }
  .woocommerce-cart-form .product-thumbnail img {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px; }
  .woocommerce-cart-form .product-name {
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1rem !important; }
    .woocommerce-cart-form .product-name a {
      font-family: "Prata", serif;
      font-size: 1.125rem; }
    .woocommerce-cart-form .product-name .variation {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 5px 10px;
      font-size: 12px;
      margin-top: 1rem;
      margin-bottom: 0px;
      font-family: "Prata", serif; }
      @media screen and (max-width: 767px) {
        .woocommerce-cart-form .product-name .variation {
          grid-template-columns: 3fr 1fr; } }
      .woocommerce-cart-form .product-name .variation dt {
        font-family: "PT Serif", serif;
        margin-right: 5px;
        font-weight: normal;
        margin-bottom: 3px;
        font-size: 0.9375rem;
        min-width: max-content; }
      .woocommerce-cart-form .product-name .variation dd {
        margin-left: 0;
        margin-right: 5px;
        margin-bottom: 3px;
        min-width: max-content; }
        .woocommerce-cart-form .product-name .variation dd p {
          font-family: "PT Serif", serif;
          margin: 0;
          display: inline-flex;
          font-size: 0.9375rem; }
  .woocommerce-cart-form .product-price {
    text-align: right; }
  .woocommerce-cart-form .product-quantity {
    text-align: right; }
    .woocommerce-cart-form .product-quantity .qty {
      padding: 9px 2px; }
  .woocommerce-cart-form .product-subtotal {
    font-weight: bold;
    text-align: right; }
    .woocommerce-cart-form .product-subtotal .tax_label {
      font-size: 11px !important;
      font-weight: normal;
      display: block; }
  .woocommerce-cart-form thead tr th {
    height: 3rem;
    line-height: 3rem;
    font-size: 0.8rem !important;
    text-transform: uppercase; }

/* Tabla del carrito de la compra -------------------------- */
@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table thead {
    display: none; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody tr {
    display: flex;
    flex-direction: column; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody td {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 0.9rem; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody td.product-thumbnail {
    display: none; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table .cart_item {
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 2px;
    margin-bottom: 10px; } }

.woocommerce-cart-form table.shop_table .cart_item td {
  border-bottom: 2px solid #f8f3ed;
  background-color: #d4d3cb; }

.woocommerce-cart-form table.shop_table td {
  padding: 20px 9px;
  vertical-align: middle;
  line-height: 1.5em; }
  @media screen and (max-width: 575px) {
    .woocommerce-cart-form table.shop_table td {
      padding: 5px 9px; } }

.woocommerce-cart-form table.shop_table td a {
  color: #1e0028; }

.woocommerce-cart-form table.shop_table th {
  font-weight: 700;
  padding: 7px 9px;
  line-height: 1.5em; }

.woocommerce-cart-form table.shop_table .actions {
  padding: 1rem 0 0 0; }
  @media (max-width: 575px) {
    .woocommerce-cart-form table.shop_table .actions::before {
      display: none; } }

.coupon {
  width: 100%;
  margin-bottom: 0.5rem; }
  .coupon label {
    display: none; }
  @media (max-width: 767px) {
    .coupon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1rem; } }
  @media (max-width: 767px) {
    .coupon input {
      margin-right: 10px;
      width: 50%; } }
  .coupon button {
    padding: 12px 15px; }

/* Atributos en tabla para responsive --------------- */
@media (max-width: 767px) {
  .woocommerce table.shop_table_responsive tr td::before,
  .woocommerce-page table.shop_table_responsive tr td::before {
    content: attr(data-title) ": ";
    font-weight: 400;
    float: left; } }

.cart_totals table.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px; }
  .cart_totals table.shop_table .order-total .amount {
    color: #83031f !important; }

.woocommerce table.shop_table .screen-reader-text {
  display: none; }

.update_cart {
  float: right; }

.cart-collaterals {
  width: 100%;
  display: flex;
  justify-content: right; }

/* CARRITO TOTALES Y TRANSPORTE ------------------- */
.cart_totals {
  display: block; }
  @media (max-width: 767px) {
    .cart_totals {
      width: 100%;
      font-size: 0.9rem !important; } }
  .cart_totals h2, .cart_totals .h2 {
    font-size: 1.5rem !important;
    margin-bottom: 0.5rem; }
  .cart_totals .shop_table {
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 2rem;
    border-radius: 3px; }
  .cart_totals tbody tr td,
  .cart_totals tbody tr th {
    padding: 12px 12px !important; }
  .cart_totals tbody tr td {
    border: 1px solid rgba(0, 0, 0, 0.25); }
    .cart_totals tbody tr td::before {
      display: none; }
  .cart_totals tbody tr th {
    font-size: 0.75rem;
    text-align: right;
    width: 25%; }
    @media (max-width: 767px) {
      .cart_totals tbody tr th {
        width: 25%; } }
  .cart_totals tbody tr th:nth-child(1) {
    background-color: #d4d3cb; }
  .cart_totals h2, .cart_totals .h2 {
    margin-top: 0; }
  .cart_totals .checkout-button {
    display: block;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }

.woocommerce-shipping-methods {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 5px !important; }
  .woocommerce-shipping-methods li {
    margin-bottom: 1rem; }
    .woocommerce-shipping-methods li input.shipping_method {
      margin-right: 5px; }
    .woocommerce-shipping-methods li label {
      font-weight: bold; }
      .woocommerce-shipping-methods li label .woocommerce-Price-amount {
        color: #7f7f7f; }
    .woocommerce-shipping-methods li label span {
      font-weight: normal; }
    .woocommerce-shipping-methods li .betrs_option_desc {
      font-size: 0.9375rem;
      line-height: 1.2rem; }
      .woocommerce-shipping-methods li .betrs_option_desc a {
        color: #128c7e; }

.woocommerce #content table.cart td.actions,
.woocommerce table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
  text-align: right; }

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
  float: left; }

.woocommerce-form-coupon-toggle {
  margin-bottom: 1rem; }

.col2-set {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem; }
  @media (max-width: 767px) {
    .col2-set {
      grid-template-columns: 1fr;
      grid-gap: 2rem; } }
  .col2-set .col-1 {
    margin-bottom: 2rem; }
  .col2-set .col-2,
  .col2-set .col-1 {
    width: 100%; }

#order_review_heading,
.woocommerce-billing-fields h3,
.woocommerce-billing-fields .h3,
.woocommerce-billing-fields h3 label span,
.woocommerce-billing-fields .h3 label span {
  font-size: 1.25rem !important;
  margin-top: 0;
  color: #83031f !important;
  font-weight: 500;
  margin-bottom: 1.5rem; }

.woocommerce-EditAccountForm {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem; }
  .woocommerce-EditAccountForm .form-row-first {
    width: 100% !important; }
  .woocommerce-EditAccountForm .form-row-last {
    width: 100% !important; }
  .woocommerce-EditAccountForm .clear {
    display: none; }
  .woocommerce-EditAccountForm .button {
    color: #83031f !important;
    font-size: 1.25rem !important; }

.woocommerce-form-login .button {
  color: #fff !important;
  font-size: 1.25rem !important; }

.woocommerce-address-fields .button {
  color: #83031f !important;
  font-size: 1.25rem !important;
  padding-left: 0 !important;
  border-left: none !important; }

.woocommerce-Addresses a.edit {
  color: #83031f !important;
  font-size: 1.125rem !important;
  text-transform: uppercase; }

.woocommerce-EditAccountForm label {
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 0.9rem; }

.woocommerce-EditAccountForm .woocommerce-input-wrapper {
  width: 100%; }

.woocommerce-EditAccountForm input,
.woocommerce-EditAccountForm textarea {
  display: block;
  width: 100% !important; }

.woocommerce-address-fields label,
.woocommerce-EditAccountForm label,
.woocommerce-checkout label {
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 0.9rem; }

.woocommerce-address-fields .woocommerce-input-wrapper,
.woocommerce-EditAccountForm .woocommerce-input-wrapper,
.woocommerce-checkout .woocommerce-input-wrapper {
  width: 100%; }

.woocommerce-address-fields input,
.woocommerce-address-fields textarea,
.woocommerce-EditAccountForm input,
.woocommerce-EditAccountForm textarea,
.woocommerce-checkout input,
.woocommerce-checkout textarea {
  display: block;
  width: 100%; }

.form-row-wide {
  margin-top: 0 !important; }

@media (min-width: 992px) {
  #billing_phone_field,
  #billing_postcode_field,
  .form-row-first {
    width: 48%;
    display: inline-block;
    margin-right: 4%; } }

@media (min-width: 992px) {
  #billing_email_field,
  #billing_city_field,
  .form-row-last {
    width: 48%;
    display: inline-block; } }

/* checkout ---------------------------------- */
@media (min-width: 992px) {
  form.woocommerce-checkout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 3rem;
    row-gap: 1rem; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #customer_details {
    grid-column: 1 / 2;
    grid-row: 1 / 3; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review_heading {
    grid-column: 2 / 3;
    grid-row: 1 / 2; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review {
    grid-column: 2 / 3;
    grid-row: 2 / 3; } }

@media (min-width: 992px) {
  #customer_details.col2-set {
    display: flex;
    flex-direction: column;
    gap: 0; } }

/* checkout ---------------------------------- */
.woocommerce-form__label-for-checkbox .woocommerce-terms-and-conditions-checkbox-text {
  display: inline;
  font-size: 1rem; }

.woocommerce-form__label-for-checkbox .woocommerce-form__input-checkbox {
  width: auto;
  margin-right: 10px;
  margin-top: 5px;
  display: inline; }

.woocommerce-table--order-details,
.woocommerce-checkout-review-order .shop_table {
  background-color: transparent;
  border: 1px solid #7f7f7f !important;
  border-radius: 5px;
  padding: 1.4rem !important;
  margin-bottom: 3rem; }

.woocommerce-table--order-details thead tr th,
.woocommerce-checkout-review-order-table thead tr th {
  text-align: left;
  padding: 9px 12px;
  font-size: 1.3rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details thead tr th,
    .woocommerce-checkout-review-order-table thead tr th {
      padding: 5px 8px; } }

.woocommerce-table--order-details tfoot tr th,
.woocommerce-table--order-details tfoot tr td,
.woocommerce-table--order-details tbody tr td,
.woocommerce-checkout-review-order-table tfoot tr th,
.woocommerce-checkout-review-order-table tfoot tr td,
.woocommerce-checkout-review-order-table tbody tr td {
  padding: 9px 12px;
  border-bottom: 1px solid #7f7f7f;
  text-align: left;
  font-size: 0.9375rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details tfoot tr th,
    .woocommerce-table--order-details tfoot tr td,
    .woocommerce-table--order-details tbody tr td,
    .woocommerce-checkout-review-order-table tfoot tr th,
    .woocommerce-checkout-review-order-table tfoot tr td,
    .woocommerce-checkout-review-order-table tbody tr td {
      padding: 5px 8px !important; } }

.woocommerce-table--order-details .order-total .amount,
.woocommerce-checkout-review-order-table .order-total .amount {
  color: #83031f !important; }

.woocommerce-checkout-review-order-table {
  font-size: 0.9rem; }
  .woocommerce-checkout-review-order-table thead .product-total,
  .woocommerce-checkout-review-order-table thead .product-name {
    font-size: 1.1rem; }

.woocommerce-checkout-payment {
  background-color: transparent;
  border: 1px solid #7f7f7f !important;
  padding: 2rem;
  border-radius: 5px; }
  @media (max-width: 767px) {
    .woocommerce-checkout-payment {
      padding: 1rem; } }
  .woocommerce-checkout-payment ul.wc_payment_methods {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
      padding-right: 1rem;
      padding-bottom: 0.5rem; }
      @media screen and (max-width: 575px) {
        .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
          padding-bottom: 0.7rem;
          padding-right: 0; } }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label {
      font-weight: 700;
      display: inline; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label .about_paypal {
      font-weight: 400; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label > img {
      margin-left: 10px;
      margin-right: 10px; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method.payment_method_bizum img {
      width: 80px; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img {
      max-width: 35px !important;
      height: auto; }
      .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img:first-child {
        margin-left: 10px !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods .input-radio {
      display: inline-block;
      width: auto;
      margin-right: 0.5rem; }
    .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
      width: 100%;
      margin: 10px 1.5rem 10px 1.5rem;
      padding: 0.5rem 1rem;
      font-size: 0.9375rem;
      border-radius: 0.625rem;
      background-color: transparent;
      border: 1px dashed #7f7f7f !important; }
      @media screen and (max-width: 575px) {
        .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
          margin-left: 0;
          margin-right: 0; } }
      .woocommerce-checkout-payment ul.wc_payment_methods .payment_box p {
        margin-bottom: 5px; }
    .woocommerce-checkout-payment ul.wc_payment_methods label {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      font-size: 0.8rem; }

@media screen and (max-width: 575px) {
  .payment_method_stripe {
    margin: 0rem !important;
    padding: 0rem !important; } }

.payment_method_stripe label[for="payment_method_stripe"] {
  width: 90% !important; }

.place-order button.button {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 1rem; }
  @media (max-width: 767px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }
  @media (min-width: 991px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }

.woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text p {
  margin-bottom: 5px;
  font-size: 0.8rem; }

.woocommerce-terms-and-conditions-wrapper a {
  color: #2cc374; }

.woocommerce-terms-and-conditions-checkbox-text {
  font-size: 1rem; }

/* Títulos ------------------------- */
.cart-empty,
.woocommerce-thankyou-order-received,
h2.wc-bacs-bank-details-heading,
.wc-bacs-bank-details-heading.h2,
h2.woocommerce-order-details__title,
.woocommerce-order-details__title.h2 {
  margin-top: 0;
  padding-top: 1rem;
  font-size: 2rem !important;
  color: #83031f;
  font-weight: bold;
  margin-bottom: 1rem; }
  @media (max-width: 767px) {
    .cart-empty,
    .woocommerce-thankyou-order-received,
    h2.wc-bacs-bank-details-heading,
    .wc-bacs-bank-details-heading.h2,
    h2.woocommerce-order-details__title,
    .woocommerce-order-details__title.h2 {
      font-size: 1.3rem !important; } }

.wc-bacs-bank-details-account-name {
  margin-top: 0; }

/* Margin bottoms ------------------------- */
.order_details,
.woocommerce-bacs-bank-details {
  margin-bottom: 3rem !important; }

.order_details,
.wc-bacs-bank-details,
.woocommerce-order-overview {
  list-style: none;
  padding: 0;
  background-color: transparent;
  padding: 1.5rem 1rem 1rem 1.5rem;
  display: flex;
  font-size: 0.9rem;
  border: 1px solid #7f7f7f;
  border-radius: 0.625rem; }
  @media (max-width: 767px) {
    .order_details,
    .wc-bacs-bank-details,
    .woocommerce-order-overview {
      flex-direction: column;
      padding: 1rem; } }
  .order_details li,
  .wc-bacs-bank-details li,
  .woocommerce-order-overview li {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid #7f7f7f; }
    @media (max-width: 767px) {
      .order_details li,
      .wc-bacs-bank-details li,
      .woocommerce-order-overview li {
        border-right: 0; } }
  @media (max-width: 767px) {
    .order_details li:last-child,
    .wc-bacs-bank-details li:last-child,
    .woocommerce-order-overview li:last-child {
      margin-bottom: 0 !important; } }
  .order_details li strong,
  .wc-bacs-bank-details li strong,
  .woocommerce-order-overview li strong {
    display: block; }

/* Order recibed ------------------------- */
.woocommerce-table--order-details {
  display: block !important;
  box-shadow: initial; }

#nxshop_spot_selection {
  background-color: #83031f;
  color: #fff; }
  @media (max-width: 767px) {
    #nxshop_spot_selection {
      display: block;
      text-align: center; } }

#nxshop_table_container {
  font-size: 0.85rem;
  margin-top: 1rem; }
  #nxshop_table_container table tbody tr td {
    vertical-align: middle !important; }
    #nxshop_table_container table tbody tr td p {
      margin-bottom: 0; }

.woocommerce-account-fields .create-account {
  padding: 1rem;
  border: 1px dashed #7f7f7f;
  border-radius: 1rem;
  color: #83031f; }

.woocommerce-form-login-toggle {
  margin-bottom: 2rem; }

.page-template-page-cart .woocommerce-form-login {
  margin-bottom: 2rem; }
  .page-template-page-cart .woocommerce-form-login > p {
    font-size: 0.75rem; }
  .page-template-page-cart .woocommerce-form-login input {
    display: block; }
  .page-template-page-cart .woocommerce-form-login .form-row {
    display: block;
    width: 100%; }
  .page-template-page-cart .woocommerce-form-login .woocommerce-form__label-for-checkbox {
    display: flex; }

#form_paytpv #storingStep {
  margin-bottom: 1.5rem !important; }
  #form_paytpv #storingStep label input {
    width: auto; }
  #form_paytpv #storingStep .button.paycomet_pay {
    display: inline-block;
    margin-top: 1.5rem;
    font-size: 1.2rem !important; }
    @media screen and (max-width: 767px) {
      #form_paytpv #storingStep .button.paycomet_pay {
        display: block;
        width: 100%; } }

#form_paytpv #paycomet-cancel {
  margin-top: 1.5rem; }
  #form_paytpv #paycomet-cancel .button.cancel {
    background-color: White !important;
    color: #7f7f7f !important;
    border: 0 !important;
    font-size: 0.75rem; }
    @media screen and (max-width: 767px) {
      #form_paytpv #paycomet-cancel .button.cancel {
        display: block;
        width: 100%; } }

@font-face {
  font-family: "WooCommerce";
  src: url("../fonts/WooCommerce.eot?jj1bj4");
  src: url("../fonts/WooCommerce.eot?jj1bj4#iefix") format("embedded-opentype"), url("../fonts/WooCommerce.ttf?jj1bj4") format("truetype"), url("../fonts/WooCommerce.woff?jj1bj4") format("woff"), url("../fonts/WooCommerce.svg?jj1bj4#WooCommerce") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="wcicon-"],
[class*=" wcicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "WooCommerce" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.wcicon-storefront:before {
  content: "\e900"; }

.wcicon-ccv:before {
  content: "\e604"; }

.wcicon-virtual:before {
  content: "\e000"; }

.wcicon-up-down:before {
  content: "\e022"; }

.wcicon-reports:before {
  content: "\e023"; }

.wcicon-refresh:before {
  content: "\e031"; }

.wcicon-navigation:before {
  content: "\e032"; }

.wcicon-status-fill:before {
  content: "\e03c"; }

.wcicon-contract:before {
  content: "\e004"; }

.wcicon-downloadable:before {
  content: "\e001"; }

.wcicon-plus:before {
  content: "\e007"; }

.wcicon-simple:before {
  content: "\e006"; }

.wcicon-on-hold:before {
  content: "\e033"; }

.wcicon-external:before {
  content: "\e034"; }

.wcicon-contract-2:before {
  content: "\e036"; }

.wcicon-expand-2:before {
  content: "\e035"; }

.wcicon-phone:before {
  content: "\e037"; }

.wcicon-user:before {
  content: "\e038"; }

.wcicon-status:before {
  content: "\e039"; }

.wcicon-status-pending:before {
  content: "\e012"; }

.wcicon-status-cancelled:before {
  content: "\e013"; }

.wcicon-west:before {
  content: "\e02f"; }

.wcicon-south:before {
  content: "\e02e"; }

.wcicon-mail:before {
  content: "\e02d"; }

.wcicon-inventory:before {
  content: "\e02c"; }

.wcicon-attributes:before {
  content: "\e02b"; }

.wcicon-north:before {
  content: "\e02a"; }

.wcicon-east:before {
  content: "\e029"; }

.wcicon-note:before {
  content: "\e028"; }

.wcicon-windows:before {
  content: "\e027"; }

.wcicon-user2:before {
  content: "\e026"; }

.wcicon-search-2:before {
  content: "\e025"; }

.wcicon-search:before {
  content: "\e024"; }

.wcicon-star-empty:before {
  content: "\e021"; }

.wcicon-share:before {
  content: "\e030"; }

.wcicon-phone-fill:before {
  content: "\e03b"; }

.wcicon-woo:before {
  content: "\e03d"; }

.wcicon-user-fill:before {
  content: "\e03a"; }

.wcicon-grouped:before {
  content: "\e002"; }

.wcicon-status-refunded:before {
  content: "\e014"; }

.wcicon-status-completed:before {
  content: "\e015"; }

.wcicon-variable:before {
  content: "\e003"; }

.wcicon-expand:before {
  content: "\e005"; }

.wcicon-status-failed:before {
  content: "\e016"; }

.wcicon-check:before {
  content: "\e017"; }

.wcicon-right:before {
  content: "\e008"; }

.wcicon-up:before {
  content: "\e009"; }

.wcicon-query:before {
  content: "\e018"; }

.wcicon-down:before {
  content: "\e00a"; }

.wcicon-truck-1:before {
  content: "\e019"; }

.wcicon-left:before {
  content: "\e00b"; }

.wcicon-truck-2:before {
  content: "\e01a"; }

.wcicon-image:before {
  content: "\e00c"; }

.wcicon-globe:before {
  content: "\e01b"; }

.wcicon-link:before {
  content: "\e00d"; }

.wcicon-gear:before {
  content: "\e01c"; }

.wcicon-calendar:before {
  content: "\e00e"; }

.wcicon-cart:before {
  content: "\e01d"; }

.wcicon-processing:before {
  content: "\e00f"; }

.wcicon-card:before {
  content: "\e01e"; }

.wcicon-view:before {
  content: "\e010"; }

.wcicon-stats:before {
  content: "\e01f"; }

.wcicon-status-processing:before {
  content: "\e011"; }

.wcicon-star-full:before {
  content: "\e020"; }

.wcicon-coupon:before {
  content: "\e600"; }

.wcicon-limit:before {
  content: "\e601"; }

.wcicon-restricted:before {
  content: "\e602"; }

.wcicon-edit:before {
  content: "\e603"; }

textarea,
select,
input,
.qty {
  padding: 12px 12px;
  border-radius: 0 !important;
  border: 1px solid #1e0028;
  font-size: 16px;
  color: #1e0028;
  background: transparent !important;
  background-size: 15px auto !important;
  background-position: right 15px top 20px !important; }
  @media (max-width: 575px) {
    textarea,
    select,
    input,
    .qty {
      font-size: 0.75rem;
      padding: 11px 11px; } }
  textarea::placeholder,
  select::placeholder,
  input::placeholder,
  .qty::placeholder {
    color: #424142;
    opacity: 1; }

.qty {
  text-align: center;
  width: 80px; }

.shop_table .qty {
  width: 70px; }

select {
  background: transparent url("../assets/ico/ico-chevron-down-dark.svg") no-repeat right center !important;
  background-size: 15px auto !important;
  border: 1px solid #7f7f7f;
  background-position: right 15px top 20px !important;
  cursor: pointer;
  /* Hide default dropdown arrow */
  appearance: none;
  transition: 0.2s all ease-in-out;
  padding-right: 2rem !important;
  font-size: 1rem; }
  select.orderby {
    padding: 8px 10px;
    box-shadow: none; }
  select:hover {
    color: #ba042c;
    transition: 0.2s all ease-in-out; }

.selection .select2-selection {
  height: 50px !important;
  border: 1px solid #7f7f7f !important;
  box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  color: #424142 !important; }

.selection .select2-selection__rendered {
  padding: 10px 14px;
  color: #424142; }

.selection .select2-selection__arrow {
  padding: 10px 12px;
  margin-top: 12px;
  margin-right: 5px; }

.selection .select2-selection__clear {
  margin-right: 1rem; }

.selection .select2-selection__arrow b {
  border-width: 10px 10px 0 10px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #424142 !important; }

.select2-container .select2-dropdown {
  background-color: transparent;
  color: #424142;
  border-color: #7f7f7f !important; }

.select2-container--default .select2-results__option[aria-selected="true"],
.select2-container--default .select2-results__option[data-selected="true"] {
  background-color: #d4d3cb !important; }

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[data-selected] {
  background-color: #d4d3cb !important; }

.qib-container .minus {
  border-radius: 4px 0 0 4px !important; }

.qib-container .plus {
  border-radius: 0 4px 4px 0 !important; }

.grid-woo {
  display: flex; }
  .grid-woo__sidebar {
    display: block; }
    @media (max-width: 767px) {
      .grid-woo__sidebar {
        display: none; } }
  .grid-woo__sidebar-responsive {
    display: block; }
    @media (min-width: 768px) {
      .grid-woo__sidebar-responsive {
        display: none; } }
  .grid-woo__content {
    width: 100%; }

.listado-categorias {
  margin-bottom: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem; }
  @media screen and (max-width: 575px) {
    .listado-categorias {
      gap: 1rem; } }
  .listado-categorias__item {
    padding: 10px 15px;
    border-radius: 4px;
    background-color: #1e2325;
    color: #fff;
    transition: 0.2s all ease-in-out; }
    .listado-categorias__item:hover {
      background-color: #1e2325;
      color: #fff;
      transition: 0.2s all ease-in-out; }
    @media screen and (max-width: 575px) {
      .listado-categorias__item {
        padding: 6px 10px; } }

.widget--woo {
  margin-bottom: 0rem; }
  @media (max-width: 575px) {
    .widget--woo {
      margin-bottom: 1rem; } }
  .widget--woo h4.widget__title, .widget--woo .widget__title.h4 {
    display: none; }
  .widget--woo input {
    box-shadow: none !important; }

.single-product .grid-woo {
  display: block; }

.single-product .grid-woo__sidebar-responsive,
.single-product .grid-woo__sidebar {
  display: none; }

.tax-product_cat .grid-woo,
.post-type-archive-product .grid-woo {
  padding-top: 2rem !important; }
  @media screen and (max-width: 767px) {
    .tax-product_cat .grid-woo,
    .post-type-archive-product .grid-woo {
      padding-top: 0.5rem !important; } }

.page-woocommerce {
  padding-top: 2rem;
  padding-bottom: 4rem; }
  .page-woocommerce--product {
    padding-top: 0; }

.term-description {
  margin-top: 2.5rem; }

.widget-tallas {
  margin-bottom: 2rem; }
  .widget-tallas__widget {
    width: 100%; }
    @media screen and (min-width: 992px) {
      .widget-tallas__widget {
        width: 30%; } }

ul.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;
  margin-top: 1rem; }
  ul.products li.product {
    background-color: #f8f3ed;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    ul.products li.product .button {
      background: #d7d7d7;
      border-color: #d7d7d7;
      border-width: 2px !important;
      color: #f8f3ed;
      border-style: solid;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      transition: 0.2s all ease-in-out; }
      ul.products li.product .button:hover .bi {
        transition: 0.2s all ease-in-out;
        margin-right: 15px; }
      ul.products li.product .button .bi {
        margin-right: 5px;
        margin-bottom: 5px;
        transition: 0.2s all ease-in-out; }
  ul.products .product_gama {
    position: absolute;
    z-index: 99;
    font-size: 0.9rem;
    border-radius: 4px;
    background-color: #1e2325;
    color: #fff;
    border-color: #1e2325;
    display: inline;
    box-shadow: none;
    padding: 10px 15px;
    transform: translate(0.5rem, 0.5rem); }
    @media screen and (max-width: 575px) {
      ul.products .product_gama {
        transform: translate(0, 0.5rem);
        padding: 6px 10px; } }
  ul.products .product__cat {
    margin-bottom: 0.33333rem;
    color: #ba042c;
    font-size: 0.75rem;
    letter-spacing: 0.5px; }
    @media (max-width: 575px) {
      ul.products .product__cat {
        margin-bottom: 0.2rem !important; } }
  ul.products .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    position: relative;
    text-align: left; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__link {
        align-items: center; } }
    ul.products .woocommerce-loop-product__link:hover .attachment-woocommerce_thumbnail {
      scale: 1.1;
      transition: 0.3s all ease-in-out; }
    ul.products .woocommerce-loop-product__link .woocommerce-loop-product__img-container {
      overflow: hidden;
      margin-bottom: 1.5rem; }
      @media screen and (max-width: 575px) {
        ul.products .woocommerce-loop-product__link .woocommerce-loop-product__img-container {
          margin-bottom: 1rem; } }
    ul.products .woocommerce-loop-product__link .onsale {
      position: absolute;
      background-color: #83031f;
      color: #fff;
      padding: 3px 8px 4px;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      top: 0.5rem;
      left: 0.5rem;
      z-index: 1; }
      @media screen and (max-width: 575px) {
        ul.products .woocommerce-loop-product__link .onsale {
          font-size: 0.7rem;
          padding: 2px 8px 3px; } }
  ul.products .woocommerce-loop-product__link .wp-post-image {
    width: 100%;
    height: auto;
    margin-bottom: 1rem; }
  ul.products .attachment-woocommerce_thumbnail {
    width: 100% !important;
    height: auto;
    transition: 0.3s all ease-in-out; }
    @media (max-width: 575px) {
      ul.products .attachment-woocommerce_thumbnail {
        margin-bottom: 0 !important; } }
  ul.products .woocommerce-loop-product__title {
    font-family: "Prata", serif;
    font-size: 1.5rem;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0.5rem;
    display: block;
    color: #1e0028; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__title {
        font-size: 1.5rem;
        font-family: "PT Serif", serif; } }
  ul.products .price .woocommerce-Price-amount {
    color: #7f7f7f;
    font-size: 1.25rem; }
  ul.products .price ins {
    text-decoration: none !important; }
    ul.products .price ins bdi {
      color: #83031f;
      font-weight: bold;
      font-size: 1.125rem;
      margin-left: 0.5rem; }

.related ul.products .product_gama {
  position: absolute;
  top: 0;
  transform: translate(1rem, 1rem); }

.columns-1 {
  grid-template-columns: 1fr; }
  .columns-1 .product {
    width: 40%;
    margin: 0 auto; }

.columns-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem 2.5rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem 1rem; } }
  @media (max-width: 575px) {
    .columns-3 {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem 1rem; } }

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem 1rem; } }
  @media (max-width: 575px) {
    .columns-4 {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem 1rem; } }

.columns-5 {
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem 2rem; }
  @media screen and (max-width: 1199px) {
    .columns-5 {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem 1rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-5 {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 3rem 2rem; } }
  @media (max-width: 767px) {
    .columns-5 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem 1rem; } }
  @media (max-width: 575px) {
    .columns-5 {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem 1rem; } }

.columns-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; } }
  @media (max-width: 575px) {
    .columns-6 {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem 1rem; } }

.products li.product-category img {
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem; }

.products li.product-category h2.woocommerce-loop-category__title, .products li.product-category .woocommerce-loop-category__title.h2 {
  font-size: 1rem;
  color: #d7d7d7;
  text-align: center; }

.term-description {
  margin-bottom: 1rem;
  font-size: 0.85rem; }

.woocommerce-result-count {
  display: inline-block;
  color: #d7d7d7;
  margin-top: 10px;
  font-size: 0.9375rem; }
  @media (max-width: 575px) {
    .woocommerce-result-count {
      font-size: 0.9375rem;
      display: none; } }

.woocommerce-ordering {
  display: inline-block;
  float: right;
  clear: both; }
  @media (max-width: 991px) {
    .woocommerce-ordering {
      float: none; } }

/* Paginador -------------------- */
.woocommerce-pagination {
  text-align: center; }
  .woocommerce-pagination ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid #1e2325;
    padding: 2px;
    border-radius: 3px; }
  .woocommerce-pagination li {
    display: inline-block; }
  .woocommerce-pagination li .page-numbers {
    padding: 10px 15px;
    background-color: #1e2325;
    border: 1px solid #1e2325;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 4px;
    font-size: 1.2rem; }
  .woocommerce-pagination li .page-numbers.current {
    background-color: transparent;
    color: #1e2325; }
  .woocommerce-pagination li .page-numbers:hover {
    background-color: transparent;
    color: #1e2325; }

.login {
  position: relative; }
  .login__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 767px) {
      .login__overlay {
        background: rgba(0, 0, 0, 0.7); } }
  .login__container {
    position: relative; }
    .login__container h2, .login__container .h2 {
      display: none; }

.page-template-page-login.logged-out .bg-login {
  background: url("../assets/site/estudio-g2-login-bg.jpg") center center;
  background-size: cover; }

.page-template-page-login.logged-in .login__overlay {
  background: none; }

.page-template-page-login.logged-out .woocommerce > h2, .page-template-page-login.logged-out .woocommerce > .h2 {
  display: none; }

.woocommerce-form-login,
.woocommerce-form-register {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem 2rem;
  background-color: #f8f3ed;
  border-radius: 0.625rem;
  box-shadow: 0px 8px 16px rgba(30, 0, 40, 0.08) !important; }
  .woocommerce-form-login .form-row,
  .woocommerce-form-register .form-row {
    width: 100%; }
  .woocommerce-form-login .woocommerce-Input,
  .woocommerce-form-register .woocommerce-Input {
    border-color: #d7d7d7; }

.woocommerce-form-row label,
.woocommerce-form-login label {
  display: block;
  margin-bottom: 8px;
  font-size: 0.9rem; }

.woocommerce-form-row input,
.woocommerce-form-login input {
  display: block;
  width: 100%; }

.woocommerce-form-row__rememberme,
.woocommerce-form-login__rememberme {
  padding-bottom: 1rem !important; }

.woocommerce-form-row__submit,
.woocommerce-form-login__submit {
  display: block;
  width: 100%; }

.woocommerce-form-row .lost_password,
.woocommerce-form-login .lost_password {
  font-size: 0.75rem;
  text-align: right; }

.page-template-page-login.logged-in .woocommerce {
  display: grid;
  grid-template-columns: 3fr 9fr 1fr;
  grid-gap: 3rem; }
  @media (max-width: 991px) {
    .page-template-page-login.logged-in .woocommerce {
      display: initial; } }

.woocommerce-MyAccount-navigation ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation ul {
      display: flex;
      padding: 0;
      background-color: initial;
      margin-top: 0;
      flex-wrap: wrap; } }

.woocommerce-MyAccount-navigation li {
  background-color: #1e2325;
  margin-bottom: 6px !important; }
  @media screen and (max-width: 991px) {
    .woocommerce-MyAccount-navigation li {
      margin-bottom: 3px; } }

.woocommerce-MyAccount-navigation li a {
  padding: 0.25rem 0.5rem;
  color: #f8f3ed;
  display: block; }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation li a {
      padding: 5px 1rem;
      background-color: #d7d7d7;
      border-radius: 50px;
      color: #f8f3ed;
      display: inline-flex; } }
  @media (max-width: 767px) {
    .woocommerce-MyAccount-navigation li a {
      font-size: 0.75rem;
      color: #f8f3ed; } }

.woocommerce-MyAccount-navigation li.is-active a {
  color: #1e2325;
  background-color: #f8f3ed;
  font-weight: bold; }

.woocommerce-MyAccount-content .woocommerce-table--order-details {
  display: block; }

.woocommerce-MyAccount-content .woocommerce-Message--info {
  display: flex;
  flex-direction: column; }

.woocommerce-MyAccount-content .woocommerce-Message .button {
  display: inline-block;
  width: max-content;
  margin-bottom: 1rem;
  padding-left: 0 !important;
  border-left: none !important;
  color: #83031f !important;
  font-size: 1.25rem !important; }

.woocommerce-EditAccountForm .woocommerce-form-row input {
  width: auto; }

.woocommerce-EditAccountForm span em {
  font-size: 0.75rem; }

.account-orders-table {
  font-size: 0.9rem;
  border-collapse: collapse; }
  @media (max-width: 767px) {
    .account-orders-table thead {
      display: none; } }
  @media (max-width: 767px) {
    .account-orders-table tbody {
      font-size: 0.75rem; } }
  @media (max-width: 767px) {
    .account-orders-table tbody tr {
      display: block;
      display: flex;
      flex-direction: column;
      background-color: #f8f3ed;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 0.625rem; } }
  .account-orders-table tbody td {
    text-align: center;
    padding: 10px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0;
    border-right: 0; }
    @media (max-width: 767px) {
      .account-orders-table tbody td {
        text-align: right;
        padding: 8px 5px; } }
  .account-orders-table tbody .view {
    padding: 6px 1rem;
    display: block; }
  .account-orders-table .woocommerce-orders-table__cell-order-status {
    font-weight: bold; }
  .account-orders-table .woocommerce-orders-table__cell-order-actions .view {
    display: inline-block; }

div[data-title="Cancelado"] {
  color: #760e0e; }

.woocommerce-ResetPassword {
  background-color: #1e2325;
  padding: 4rem;
  border-radius: 0.625rem; }
  @media screen and (min-width: 992px) {
    .woocommerce-ResetPassword {
      width: 50%;
      margin: 0 auto; } }
  .woocommerce-ResetPassword .woocommerce-form-row {
    width: 100%; }
  .woocommerce-ResetPassword .woocommerce-Input {
    border-color: #d7d7d7; }

.woocommerce {
  color: #1e0028; }

.woocommerce-notices-wrapper {
  display: block;
  padding-top: 1rem;
  margin-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-notices-wrapper {
      margin-bottom: 1rem; } }
  .woocommerce-notices-wrapper:empty {
    margin-bottom: 0;
    padding: 0; }
  .woocommerce-notices-wrapper .woocommerce-error {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0; }
    .woocommerce-notices-wrapper .woocommerce-error li {
      color: red; }
  .woocommerce-notices-wrapper .woocommerce-message {
    display: block;
    padding: 1rem;
    background-color: transparent;
    color: #1e0028;
    box-shadow: 0px 8px 16px rgba(30, 0, 40, 0.08) !important;
    border-radius: 0.625rem;
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    overflow: hidden; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message {
        display: block;
        font-size: 0.8rem;
        text-align: left;
        background-color: #faffd0;
        border: 1px solid #b5bb84;
        border-radius: 0;
        padding: 1rem;
        box-shadow: none;
        text-align: center; } }
    .woocommerce-notices-wrapper .woocommerce-message a.button {
      text-align: center; }
      @media (max-width: 575px) {
        .woocommerce-notices-wrapper .woocommerce-message a.button {
          float: left;
          display: inline-block;
          padding: 10px 15px;
          width: 100%;
          height: auto;
          margin-bottom: 0.5rem; } }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message a.restore-item {
        height: auto;
        display: inline; } }
  .woocommerce-notices-wrapper .button {
    display: inline-block;
    border-radius: 4px;
    background-color: #1e2325;
    color: #fff !important;
    border-color: #1e2325 !important;
    margin-right: 2rem;
    font-weight: normal;
    transition: 0.2s all ease-in-out; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .button {
        width: 230px;
        margin-right: 1rem; } }
    .woocommerce-notices-wrapper .button:hover {
      background-color: #1e2325;
      color: #fff !important;
      border-color: #1e2325 !important;
      transition: 0.2s all ease-in-out; }

.woocommerce-NoticeGroup-checkout .woocommerce-error {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0; }
  .woocommerce-NoticeGroup-checkout .woocommerce-error li {
    color: #880f0f; }

div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  column-gap: 2.5rem;
  row-gap: 5rem;
  position: relative; }
  @media (max-width: 767px) {
    div.product {
      display: block; } }
  div.product .onsale {
    position: absolute;
    background-color: #83031f;
    color: #fff;
    padding: 3px 8px 4px;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 1; }
    @media screen and (max-width: 575px) {
      div.product .onsale {
        font-size: 0.7rem;
        padding: 2px 8px 3px; } }
  div.product .woocommerce-product-gallery {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    position: relative; }
  div.product .summary {
    grid-column: 6 / 11;
    grid-row: 1 / 2; }
    @media screen and (max-width: 991px) {
      div.product .summary {
        grid-column: 6 / 11; } }
  div.product .woocommerce-tabs {
    grid-column: 1 / 6;
    grid-row: 2 / 3; }
  div.product .related {
    grid-column: 6 / 11;
    grid-row: 2 / 3; }
  div.product .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4; }

@media (max-width: 767px) {
  .summary {
    margin-bottom: 3rem !important; } }

@media screen and (max-width: 575px) {
  .summary {
    margin-bottom: 3rem; } }

.summary .product_title {
  margin-top: 0;
  padding-top: 0;
  font-size: 2rem !important;
  font-weight: bold;
  margin-bottom: 0rem;
  color: #1e0028; }
  @media (max-width: 575px) {
    .summary .product_title {
      font-size: 1.4rem !important;
      margin-bottom: 0.3rem;
      font-family: "PT Serif", serif; } }

.summary ins {
  text-decoration: none !important; }
  .summary ins bdi {
    color: #83031f; }

.summary del {
  margin-right: 0.5rem; }
  .summary del bdi {
    font-size: 80%; }

.summary .screen-reader-text {
  display: none; }

.summary .cart.variations_form {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem; }

.summary .cart {
  margin-bottom: 2rem;
  display: flex;
  align-items: center; }

.summary .cart .qty {
  padding: 8px 8px;
  font-size: 1rem;
  text-align: center;
  box-shadow: none; }
  @media (max-width: 575px) {
    .summary .cart .qty {
      padding: 10px 5px;
      width: 80px; } }

.summary .quantity {
  margin-right: 1rem; }
  .summary .quantity.hidden {
    display: none; }

.summary .woocommerce-variation-add-to-cart {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .summary .woocommerce-variation-add-to-cart .quantity {
    flex: 1 1 30%; }
  .summary .woocommerce-variation-add-to-cart .single_add_to_cart_button {
    width: 100%;
    flex: 1 1 80% !important;
    text-align: center; }

.ncdgw_textarea_wrapper {
  margin-top: 0.5rem; }

.product_meta {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  font-family: "Prata", serif; }
  @media screen and (max-width: 767px) {
    .product_meta {
      display: none; } }
  .product_meta > span {
    display: block; }
  .product_meta a {
    color: #d7d7d7;
    text-decoration: underline; }

.summary .price {
  margin: 0 0 1rem;
  font-weight: normal;
  color: #7f7f7f;
  font-size: 1.2rem; }
  @media (max-width: 575px) {
    .summary .price {
      border: none;
      margin: 0 0 1rem;
      padding: 0; } }
  @media (max-width: 390px) {
    .summary .price {
      margin: 0 0 0.5rem; } }

.summary form.cart {
  display: flex; }

.summary #wccf_product_field_master_container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 0.5rem; }

.summary #wccf_product_field_torneo_genero_container,
.summary #wccf_product_field_torneo_genre_container {
  grid-column: 1 / span 2; }
  .summary #wccf_product_field_torneo_genero_container ul,
  .summary #wccf_product_field_torneo_genre_container ul {
    display: flex; }
    .summary #wccf_product_field_torneo_genero_container ul li,
    .summary #wccf_product_field_torneo_genre_container ul li {
      margin-right: 1rem; }
      .summary #wccf_product_field_torneo_genero_container ul li:nth-last-child(1),
      .summary #wccf_product_field_torneo_genre_container ul li:nth-last-child(1) {
        margin-right: 0; }
      .summary #wccf_product_field_torneo_genero_container ul li label,
      .summary #wccf_product_field_torneo_genre_container ul li label {
        margin-left: 0.25rem; }

.summary .woocommerce-product-details__short-description {
  margin-bottom: 1.4rem;
  font-size: 1rem;
  color: #424142; }

.summary table.variations label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  color: #1e0028; }

.summary table.variations .reset_variations {
  font-size: 12px;
  color: #83031f; }

.summary .product_meta .sku_wrapper {
  display: none; }

.summary .product_meta .posted_in {
  font-size: 1rem;
  font-weight: bold; }
  .summary .product_meta .posted_in a {
    color: #83031f;
    font-weight: normal; }

.summary .woocommerce-variation-price {
  margin-bottom: 0; }
  .summary .woocommerce-variation-price .price {
    line-height: 1;
    margin-bottom: 0; }

.vi-wpvs-variation-wrap-image .vi-wpvs-option-wrap {
  padding: 2px !important;
  border-radius: 0 !important;
  width: 70px !important;
  height: 70px !important; }

.vi-wpvs-variation-style-content .vi-wpvs-option-wrap {
  border-radius: 5px !important; }

.coleccion {
  margin-bottom: 2rem; }
  .coleccion__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px; }
  .coleccion__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px; }
  .coleccion__quantity label {
    display: none; }
  .coleccion__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.5rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #7f7f7f;
    min-width: 50px; }
  .coleccion__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: #d7d7d7;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px; }
  .coleccion__label label {
    display: flex;
    margin-bottom: 3px; }
  .coleccion__label label a {
    font-size: 13px;
    font-weight: 400;
    color: #424142; }
  .coleccion__price {
    text-align: right;
    font-size: 1rem;
    color: #886d50;
    font-weight: 700; }
    .coleccion__price .stock {
      margin: 0;
      font-size: 0.9375rem;
      color: #7f7f7f; }

.garantia-extras {
  font-size: 0.9375rem; }
  .garantia-extras a {
    color: #f5f5f5;
    border-bottom: 1px solid;
    font-weight: normal; }
  .garantia-extras__nav {
    list-style: none;
    padding-left: 0; }
  .garantia-extras__item {
    color: #424142;
    margin-bottom: 5px; }
    @media (max-width: 575px) {
      .garantia-extras__item {
        margin-bottom: 6px; } }
    .garantia-extras__item .bi {
      margin-right: 5px; }
    .garantia-extras__item--share a {
      border-bottom: 0;
      font-weight: bold;
      color: #128c7e; }
  .garantia-extras a.verde-whatsapp {
    color: #128c7e; }
    @media (max-width: 575px) {
      .garantia-extras a.verde-whatsapp {
        background-color: #128c7e;
        color: #f8f3ed;
        display: inline-block;
        border-radius: 50px;
        padding: 0 8px;
        text-decoration: none; } }

.garantia-sat {
  display: flex;
  align-items: center;
  background-color: rgba(44, 195, 116, 0.1);
  padding: 7px 10px;
  border-radius: 5px;
  border: 2px dashed #9fe9c2; }
  .garantia-sat:hover {
    text-decoration: none;
    border: 2px dashed #23995b; }
  .garantia-sat__icon {
    flex: 0 0 40px;
    margin-right: 1rem; }
    .garantia-sat__icon img {
      width: 100%;
      height: auto; }
  .garantia-sat__content {
    color: #424142;
    font-family: "Prata", serif;
    line-height: 1.4; }
    .garantia-sat__content strong {
      color: #197042; }

@media (max-width: 767px) {
  .woocommerce-tabs {
    margin-bottom: 7.5rem; } }

@media screen and (max-width: 575px) {
  .woocommerce-tabs {
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -11px;
    margin-right: -11px;
    margin-bottom: 3rem; } }

.woocommerce-tabs .wc-tab h2, .woocommerce-tabs .wc-tab .h2 {
  margin-top: 0;
  font-size: 1.5rem; }
  @media (max-width: 767px) {
    .woocommerce-tabs .wc-tab h2, .woocommerce-tabs .wc-tab .h2 {
      display: none; } }

.woocommerce-tabs .wc-tab {
  color: #424142;
  padding-top: 1rem;
  border-radius: 0.625rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .wc-tab {
      padding-top: 1rem; } }
  .woocommerce-tabs .wc-tab > div {
    font-family: "PT Serif", serif; }

.woocommerce-tabs #tab-description p,
.woocommerce-tabs #tab-description ul,
.woocommerce-tabs #tab-description h2,
.woocommerce-tabs #tab-description .h2,
.woocommerce-tabs #tab-description h3,
.woocommerce-tabs #tab-description .h3,
.woocommerce-tabs #tab-description h4,
.woocommerce-tabs #tab-description .h4,
.woocommerce-tabs #tab-description h5,
.woocommerce-tabs #tab-description .h5 {
  margin-bottom: 1.5rem; }


.woocommerce-tabs #tab-description h3,
.woocommerce-tabs #tab-description .h3 {
  font-size: 1.3rem;
  font-weight: 700;
  color: #83031f; }

.woocommerce-tabs .tabs {
  padding-left: 0;
  list-style: none;
  display: flex;
  border-bottom: 1px solid #1e2325; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .tabs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.3rem; } }
  @media screen and (min-width: 576px) {
    .woocommerce-tabs .tabs li {
      margin-right: 0.3rem; } }
  .woocommerce-tabs .tabs li:nth-child(1) a {
    padding-left: 0; }
  .woocommerce-tabs .tabs li:nth-last-child(1) a {
    padding-right: 0; }
  .woocommerce-tabs .tabs li a {
    display: block;
    position: relative;
    padding: 4px 8px;
    background-color: transparent;
    color: #1e2325;
    transition: 0.2s all ease-in-out;
    font-size: 1.125rem; }
    @media screen and (max-width: 575px) {
      .woocommerce-tabs .tabs li a {
        text-align: center; } }
    @media screen and (max-width: 380px) {
      .woocommerce-tabs .tabs li a {
        padding: 4px 6px !important;
        font-size: 0.75rem; } }
    .woocommerce-tabs .tabs li a:hover {
      transform: translateY(-2px);
      transition: 0.2s all ease-in-out; }
  .woocommerce-tabs .tabs li.active a {
    color: #1e2325;
    font-weight: bold;
    background-color: transparent;
    position: relative;
    transform: translateY(0px); }
  .woocommerce-tabs .tabs .woocommerce-Tabs-panel {
    color: #424142; }
    .woocommerce-tabs .tabs .woocommerce-Tabs-panel h2, .woocommerce-tabs .tabs .woocommerce-Tabs-panel .h2 {
      color: #1e0028; }

.comment-form-email label,
.comment-form-author label {
  display: block; }

.wp-comment-cookies-consent label {
  font-size: 0.75rem !important; }

.woocommerce-product-attributes {
  width: 100%; }
  @media screen and (max-width: 575px) {
    .woocommerce-product-attributes-item {
      font-size: 0.8rem; } }
  .woocommerce-product-attributes-item__label {
    background-color: transparent;
    width: 25%;
    text-align: right;
    padding-right: 1rem; }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__label {
        padding-right: 0;
        text-align: center; } }
  .woocommerce-product-attributes-item__value {
    padding-left: 1rem;
    background-color: #d4d3cb; }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__value {
        padding-left: 0;
        text-align: center; } }
    .woocommerce-product-attributes-item__value p {
      margin: 0.5rem 0; }

.woocommerce-product-gallery {
  position: relative; }
  @media screen and (max-width: 767px) {
    .woocommerce-product-gallery {
      margin-bottom: 1rem; } }
  .woocommerce-product-gallery__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10; }
  @media screen and (min-width: 768px) {
    .woocommerce-product-gallery__wrapper {
      position: sticky;
      top: 75px; } }
  .woocommerce-product-gallery img {
    width: 100%;
    height: auto; }

.woocommerce-product-gallery__wrapper {
  margin-bottom: 1rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-product-gallery__wrapper {
      margin-bottom: 0.5rem; } }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image {
    width: auto;
    display: inline-flex;
    margin-right: 5px;
    margin-bottom: 5px; }
    .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image a img {
      width: 90px;
      height: auto; }
      @media (max-width: 767px) {
        .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image a img {
          width: 70px; } }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:first-child {
    width: 100%;
    display: block;
    margin-bottom: 5px; }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image--placeholder {
    width: 100%;
    height: auto; }
  .woocommerce-product-gallery__wrapper .wp-post-image {
    width: 100% !important;
    height: auto; }

.x-container {
  display: flex;
  overflow-y: auto; }

.products.related > h2, .products.related > .h2,
.products.upsells > h2,
.products.upsells > .h2 {
  margin-bottom: 1.2rem !important;
  font-size: 1.2rem; }

section.related ul.products {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  section.related ul.products li.product {
    display: block;
    width: 100%;
    height: auto;
    clear: both; }
  section.related ul.products .attachment-woocommerce_thumbnail {
    float: left !important;
    width: 100% !important;
    height: auto;
    margin-right: 1rem; }
  section.related ul.products .woocommerce-loop-product__link {
    width: 100%;
    align-items: inherit;
    display: block !important;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center; }
    section.related ul.products .woocommerce-loop-product__link .vi_wpvs_loop_variation_form {
      display: flex;
      justify-content: center !important; }
  section.related ul.products .woocommerce-loop-product__title {
    font-size: 0.9rem;
    width: auto;
    color: #7f7f7f; }

.garantia {
  display: flex;
  gap: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem; }
  .garantia__item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.9375rem;
    flex: 1 0 30%; }
  .garantia__ico {
    margin-right: 10px; }
    .garantia__ico svg {
      width: 25px;
      height: 25px; }
    .garantia__ico .bi {
      font-size: 2rem;
      color: #7f7f7f; }

#review_form .comment-reply-title {
  font-size: 0.75rem;
  margin-bottom: 1rem;
  display: block; }

.product .star-rating span:before,
.star-rating::before {
  color: #886d50 !important;
  border-color: #886d50 !important; }

.woocommerce-product-rating .woocommerce-review-link {
  font-size: 0.75rem;
  color: #424142; }

.stock.out-of-stock {
  display: none; }

.notas {
  font-size: 0.9375rem; }

.compres {
  padding: 2rem 0; }
  .compres__content {
    display: flex;
    justify-content: space-around; }
    @media screen and (max-width: 575px) {
      .compres__content {
        flex-direction: column; } }
  .compres__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center; }
    @media screen and (max-width: 575px) {
      .compres__item {
        margin-bottom: 1rem; } }
    .compres__item:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 575px) {
    .compres__icono {
      flex: 0 0 10%;
      display: flex;
      align-items: center;
      justify-content: center; } }
  .compres__icono .bi {
    font-size: 2rem;
    color: #83031f; }
  .compres__titulo {
    font-weight: 500;
    font-size: 0.9375rem;
    color: #424142; }
    .compres__titulo span {
      display: block;
      color: #b6b6b6; }

/**
	 * Star ratings
	 */
.star-rating {
  float: right;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 5.4em;
  font-family: "WooCommerce"; }
  .star-rating::before {
    content: "\e020\e020\e020\e020\e020";
    float: left;
    top: 0;
    left: 0;
    position: absolute; }
  .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em; }
  .star-rating span::before {
    content: "\e021\e021\e021\e021\e021";
    top: 0;
    position: absolute;
    left: 0; }

.woocommerce-product-rating {
  line-height: 2;
  display: block; }
  .woocommerce-product-rating .star-rating {
    margin: 0.5em 4px 0 0;
    float: left; }

.products .star-rating {
  display: block;
  margin: 0 0 0.5em;
  float: none; }

.hreview-aggregate .star-rating {
  margin: 10px 0 0; }

#review_form #respond {
  position: static;
  margin: 0;
  width: auto;
  padding: 0;
  background: transparent none;
  border: 0; }
  #review_form #respond p {
    margin: 0 0 10px; }
  #review_form #respond .form-submit input {
    left: auto; }
  #review_form #respond textarea {
    box-sizing: border-box;
    width: 100%; }

p.stars a {
  position: relative;
  height: 1em;
  width: 1em;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none; }
  p.stars a::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    font-family: "WooCommerce";
    content: "\e021";
    text-indent: 0; }
  p.stars a:hover ~ a::before {
    content: "\e021"; }

p.stars:hover a::before {
  content: "\e020"; }

p.stars.selected a.active::before {
  content: "\e020"; }

p.stars.selected a.active ~ a::before {
  content: "\e021"; }

p.stars.selected a:not(.active)::before {
  content: "\e020"; }

.commentlist {
  list-style: none;
  padding-left: 0; }
  .commentlist .comment_container {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    margin-bottom: 5px !important; }
    .commentlist .comment_container .avatar {
      width: 40px;
      height: 40px;
      display: none; }
    .commentlist .comment_container .comment-text {
      width: 100%; }
      .commentlist .comment_container .comment-text .star-rating {
        float: none; }
      .commentlist .comment_container .comment-text .meta {
        margin-top: .3rem;
        font-size: 1rem;
        margin-bottom: .3rem !important; }
      .commentlist .comment_container .comment-text .description p {
        margin-top: 0; }

.yith-wapo-addon {
  .options.per-row-6 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    gap: $space-01 0.6rem;
    .yith-wapo-option {
      max-width: 100% !important;
    }
    .clear {
      display: none;
    }
  }
  .options.per-row-5 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(42px, 1fr));
    gap: $space-01 $space-05;
    .yith-wapo-option {
      max-width: 100% !important;
    }
    .clear {
      display: none;
    }
  }

  .options.per-row-4 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
    gap: $space-01 $space-05;
    .yith-wapo-option {
      max-width: 100% !important;
    }
    .clear {
      display: none;
    }
  }

  .options.per-row-3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
    gap: $space-01 $space-05;
    .yith-wapo-option {
      max-width: 100% !important;
    }
    .clear {
      display: none;
    }
  }

  .options.per-row-2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
    gap: $space-01 $space-05;

    .yith-wapo-option {
      max-width: 100% !important;
    }
    .clear {
      display: none;
    }
  }
  .options.per-row-1 {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
    // gap: $space-01 $space-05;
    .yith-wapo-option {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.wapo-addon-title {
  width: 100%;
  margin-right: 1rem;
  font-size: 1rem !important;
  color: $color-text-titles;
  font-weight: bold;
  letter-spacing: 1px;
}

.yith-wapo-option {
  margin: 0 !important;
  .image img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  label,
  .label {
    display: none;
  }

  label {
    min-width: 44px !important;
  }

  label:hover {
    border-color: $color-dark !important;
  }

  input[type="text"] {
    padding: 8px 6px !important;
    border-radius: 4px !important;
  }
}

.single-product {
  #wapo-total-price-table .wapo-product-price {
    display: none;
  }

  #yith-wapo-addon-6,
  #yith-wapo-addon-16,
  #yith-wapo-addon-20,
  #yith-wapo-addon-27,
  #yith-wapo-addon-28,
  #yith-wapo-addon-32,
  #yith-wapo-addon-33,
  #yith-wapo-addon-34,
  #yith-wapo-addon-35,
  #yith-wapo-addon-36,
  #yith-wapo-addon-43,
  #yith-wapo-addon-44,
  #yith-wapo-addon-45,
  #yith-wapo-addon-46,
  #yith-wapo-addon-47,
  #yith-wapo-addon-48,
  #yith-wapo-addon-49,
  #yith-wapo-addon-50,
  #yith-wapo-addon-53,
  #yith-wapo-addon-58,
  #yith-wapo-addon-59,
  #yith-wapo-addon-61,
  #yith-wapo-addon-65,
  #yith-wapo-addon-67,
  #yith-wapo-addon-68,
  #yith-wapo-addon-69,
  #yith-wapo-addon-70,
  #yith-wapo-addon-72,
  #yith-wapo-addon-76,
  #yith-wapo-addon-81,
  #yith-wapo-addon-82,
  #yith-wapo-addon-83,
  #yith-wapo-addon-84,
  #yith-wapo-addon-85,
  #yith-wapo-addon-86,
  #yith-wapo-addon-87,
  #yith-wapo-addon-88,
  #yith-wapo-addon-92,
  #yith-wapo-addon-96,
  #yith-wapo-addon-97,
  #yith-wapo-addon-98,
  #yith-wapo-addon-99,
  #yith-wapo-addon-100,
  #yith-wapo-addon-101,
  #yith-wapo-addon-105,
  #yith-wapo-addon-109,
  #yith-wapo-addon-110,
  #yith-wapo-addon-111,
  #yith-wapo-addon-112,
  #yith-wapo-addon-113,
  #yith-wapo-addon-114,
  #yith-wapo-addon-118,
  #yith-wapo-addon-122,
  #yith-wapo-addon-123,
  #yith-wapo-addon-124,
  #yith-wapo-addon-125,
  #yith-wapo-addon-126,
  #yith-wapo-addon-127,
  #yith-wapo-addon-131,
  #yith-wapo-addon-135,
  #yith-wapo-addon-136,
  #yith-wapo-addon-137,
  #yith-wapo-addon-138,
  #yith-wapo-addon-139,
  #yith-wapo-addon-140,
  #yith-wapo-addon-144,
  #yith-wapo-addon-148,
  #yith-wapo-addon-149,
  #yith-wapo-addon-150,
  #yith-wapo-addon-151,
  #yith-wapo-addon-152,
  #yith-wapo-addon-153,
  #yith-wapo-addon-157 {
    .label_price {
      display: block;
      text-decoration: none;
      line-height: var(--yith-wccl-select-option-size, 40px);
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      min-width: var(--yith-wccl-select-option-size, 40px);
      height: var(--yith-wccl-select-option-size, 40px);
      border-radius: 4px;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      border-color: $color-dark;
      background-color: $color-dark;
      color: $color-background;
    }
  }
}

.yith_wccl_is_custom {
  .select_option_label {
    border-radius: 4px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin: 0 !important;
    border-color: $color-dark;
    background-color: $color-dark;
    color: $color-background;
  }
}

.variations {
  width: 100%;
  margin-bottom: 20px;
  tr {
    display: flex;
    flex-direction: column;
  }

  th.label label {
    margin-top: 1rem;
    margin-bottom: $space-05;
    font-size: 1rem !important;
    text-transform: none !important;
  }

  .select_box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    gap: $space-01 $space-05;
  }

  .select_box_image {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(44px, 1fr));
    gap: $space-01 $space-05;

    .select_option_image {
      max-width: 44px;
      border-radius: 4px;
      margin: 0;
      border: 2px solid #7a7a7a;
      img {
        border-radius: 2px;
      }
    }
  }
}

.woocommerce .summary {
  .woocommerce-variation-price {
    //display: none;
  }
}

// .single-product.woocommerce form.cart,
.single-product.woocommerce .woocommerce-variation-add-to-cart {
  display: grid !important;
  grid-template-columns: auto 1fr;
  gap: $space-1;
}

.yith-wapo-container {
  grid-column: 1 / span 2;
}

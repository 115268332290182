.contacto {
  background-color: $color-background;
  &__content {
    color: $color-text-body;
    display: grid;
    grid-template-columns: 1fr 2fr;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__col1 {
    @media screen and (max-width: 991px) {
      margin-bottom: $space-1;
    }
  }

  &__col2 {
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    color: $color-text-body;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  &__descripcion {
    margin-bottom: 2rem;
    line-height: 1.8;
  }

  &__telefonos {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    font-size: $font-size;
    a {
      text-decoration: none;
    }
  }

  &__telefonos-item {
    display: flex;
    margin-bottom: 0.2rem;
    .bi {
      margin-right: 0.5rem;
    }
    &:nth-last-child(1) {
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
  }

  &__social {
    .zoom-social-icons-list {
      margin-left: 0 !important;
      display: flex;
      justify-content: flex-start;
      gap: $space-05;
    }

    .social-icon {
      font-size: 24px !important;
      padding: 0.5rem !important;
      background-color: $color-dark !important;
      color: $color-background !important;
      transition: $trans;
      @media screen and (max-width: 767px) {
        font-size: 24px !important;
        transition: $trans;
      }
      &:hover {
        background-color: $color-dark !important;
        color: $color-background !important;
        transition: $trans;
        box-shadow: $box-shadow-small;
        transform: translateY(-2px);
      }
    }
  }
}

.ubicacion {
  background-color: $color-background;
  iframe {
    width: 100%;
    height: 550px;
    @media screen and (max-width: 767px) {
      height: 500px;
    }
    @media screen and (max-width: 575px) {
      height: 400px;
    }
    @media screen and (max-width: 480px) {
      height: 320px;
    }
  }
}

.header {
  padding: $font-size 0;
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: $trans;
  @media screen and (max-width: 575px) {
    padding-top: $font-size-smaller;
    padding-bottom: $font-size-smaller;
  }

  &--sticky {
    padding: $space-05 0;
    transition: $trans;

    .logo {
      width: 120px !important;
      transition: $trans;
    }
    .header__burger {
      i {
        font-size: 2rem;
        transition: $trans;
      }
    }
    .header__woo-item--carrito {
      a {
        font-size: 1.4rem;
        transition: $trans;
      }
    }
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: $space-1;
    padding-left: $font-size * 2;
    padding-right: $font-size * 2;
    @media screen and (max-width: 575px) {
      padding-left: $font-size;
      padding-right: $font-size;
    }
  }
  &__burger {
    line-height: 1;
    transition: $trans;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: $trans-2;
    width: 120px;
    @media screen and (max-width: 575px) {
      width: 90px;
    }
    .offcanvas-button-open {
      background-color: $color-background;
      padding: 0.4rem;
      border-radius: $space-3;
      transition: $trans;
    }

    &:hover {
      .offcanvas-button-open {
        padding: 0;
      }
      .header__burger--text {
        opacity: 1;
        transition: $trans;
      }
      transform: scale(1.1);
      transition: $trans;
      background-color: $color-background;
      padding: 0.4rem;
      border-radius: $space-3;
    }
    i {
      color: $color-dark;
      font-size: 25px;
      transition: $trans;
      &:hover {
        color: $color-dark;
      }
      @media screen and (max-width: 575px) {
        font-size: 20px;
      }
    }
    &--text {
      margin-left: 0.5rem;
      opacity: 0;
      transition: $trans;
    }
  }

  &__logo {
    .logo {
      width: 160px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 575px) {
        width: 140px;
        transition: $trans;
      }
    }
  }

  &__woo {
    display: flex;
    justify-content: flex-end;
    width: 120px;

    // @media screen and (max-width: 1220px) {
    //   //margin-left: auto;
    //   transition: $trans;
    // }
    @media screen and (max-width: 575px) {
      width: 90px;
    }
  }

  &__woo-item {
    padding: 0.5rem 0.5rem;
    background-color: $color-background;
    padding: 0.4rem 0.5rem;
    border-radius: $space-3;
    @media screen and (max-width: 1220px) {
      padding-top: 0;
      padding-bottom: 0;
    }
    a {
      i {
        line-height: 1;
        font-size: 2rem;
        color: $color-dark;
        //mix-blend-mode: difference;
        @media screen and (max-width: 575px) {
          font-size: 28px;
        }
      }
      &:hover {
        //color: $color-grey-5;
        transform: scale(1.2);
      }
      @media screen and (max-width: 1220px) {
        font-size: 1.7rem;
      }
    }
  }

  &__woo-item--carrito {
    transition: $trans;

    &:hover {
      transform: scale(1.1);
      transition: $trans;
    }
  }
}

.sticky-header-item {
  position: fixed;
  z-index: 999;
  transition: $trans;

  &--burger {
    top: 1.3rem;
    left: $space-02;
    @media screen and (max-width: 991px) {
      top: 1.1rem;
    }
    @media screen and (max-width: 575px) {
      top: $font-size;
      left: $font-size;
    }
  }
  &--cart {
    top: $space-01;
    right: $space-02;
    @media screen and (max-width: 575px) {
      top: 0.75rem;
      right: $font-size;
    }
  }
}

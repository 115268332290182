#submit_bizum_payment_form,
#submit_redsys_payment_form {
  font-size: 0.9rem;
  background-color: transparent;
  color: $color-secondary-dark !important;
  border-color: transparent !important;
  margin-right: 1rem;
  box-shadow: none;
  width: auto !important;
  @include button;
  display: inline-flex !important;
  &:hover {
    background-color: transparent;
    color: $color-secondary-dark !important;
    border-color: transparent !important;
    transform: translateY(-2px);
    transition: $trans;
  }
}

.button.cancel {
  @include button;
  background-color: $color-grey-4;
  border-color: $color-grey-4 !important;
  color: $color-secondary-dark !important;
  &:hover {
    background-color: $color-grey-4;
    border-color: $color-grey-4 !important;
    color: $color-secondary-dark !important;
    transform: translateY(-2px);
    transition: $trans;
  }
}

.submit,
.button {
  font-size: 0.9rem;
  border-radius: 4px !important;
  background-color: $color-dark !important;
  color: $color-white !important;
  border-color: $color-dark !important;
  display: inline;
  box-shadow: none;
  // @include button;
  transition: $trans;
  padding: 10px 15px !important;
  &:hover {
    border-radius: 4px;
    background-color: $color-dark;
    color: $color-white !important;
    border-color: $color-dark !important;
    transform: translateY(-2px);
    transition: $trans;
  }
}

.single_add_to_cart_button {
  padding: 10px 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto;
  font-size: 1rem !important;
  letter-spacing: 0;
  margin-top: 1rem;
  @media (max-width: 575px) {
    font-size: 0.9rem !important;
    padding: 0.8rem 3rem !important;
  }
  &::before {
    @media (max-width: 575px) {
      font-size: 1rem;
      margin-right: 0.6rem;
    }
  }
  &:hover {
    //background-color: $color-grey-2 !important;
  }
}

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important;
}

form.cart {
  @media screen and (max-width: 357px) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.single_add_to_cart_button.disabled {
  background-color: none;
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background-color: none !important;
  }
}

.checkout-button,
.single_add_to_cart_button {
  background-color: $color-dark;
  color: $color-white !important;
  border-color: $color-dark !important;
  font-weight: 700;
  border-radius: 4px;
  &:hover {
    background-color: color-dark;
    color: $color-white !important;
    border-color: $color-dark !important;
  }
}

li.product {
}

.button[aria-disabled="false"] {
  border: 0;
}

.button[aria-disabled="true"] {
  background-color: transparent;
  color: $color-grey-4 !important;
  border: 0;
  cursor: not-allowed;
}

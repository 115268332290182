.footer {
  padding: $space-4 0 $space-2;
  background-color: $color-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  @media screen and (max-width: 480px) {
    padding-top: $space-2;
  }
  &__item {
    &--social {
      .zoom-social-icons-list {
        margin-left: 0 !important;
        display: flex;
        justify-content: center;
        gap: $space-05;
      }

      .social-icon {
        font-size: 24px !important;
        padding: 0.75rem !important;
        background-color: $color-dark !important;
        color: $color-background !important;
        transition: $trans;
        @media screen and (max-width: 767px) {
          font-size: 24px !important;
          transition: $trans;
        }
        &:hover {
          background-color: $color-dark !important;
          color: $color-background !important;
          transition: $trans;
          box-shadow: $box-shadow-small;
          transform: translateY(-2px);
        }
      }
    }

    &--nav {
      display: flex;
      justify-content: center;
      gap: $space-05 $space-1;
      text-align: center;
      @media screen and (max-width: 575px) {
        flex-wrap: wrap;
      }
    }

    .widget_nav_menu {
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        gap: $space-05 $space-1;
        text-align: center;
        li a {
          color: $color-text-body;
        }
        @media screen and (max-width: 575px) {
          flex-wrap: wrap;
        }
      }
    }
  }

  &__shop {
    .widget_nav_menu ul li a {
      color: $color-grey-4;
    }
  }

  &__copy {
    color: $color-text-body;
  }
}

.widget-footer {
  &__title {
    text-align: center;
    color: $color-text-titles;
  }
}

.footer-basic {
  footer {
    background-color: transparent;
  }
  .copyright {
    font-size: 2rem;
    color: $color-white;
  }
}

$color-primary: #886d50;
$color-primary-light: #978671;
$color-primary-dark: #56432f;

$color-secondary: #ba042c;
$color-secondary-light: #eba1b1;
$color-secondary-dark: #83031f;

$color-accent: #2cc374;
$color-accent-light: #8fd6b1;
$color-accent-dark: #0b9d51;

$color-grey-1: #262626;
$color-grey-2: #424142;
$color-grey-3: #7f7f7f;
$color-grey-4: #b6b6b6;
$color-grey-5: #d7d7d7;
$color-grey-6: #f5f5f5;

$color-white: #fff;
$color-dark: #1e2325;
$color-darker: #1a1e20;
$color-background: #f8f3ed;
$color-background-offCanvas: #d4d3cb;

$color-whatsapp-dark: #128c7e;
$color-whatsapp-light: #25d366;

$color-text-body: $color-grey-2;
$color-text-muted: $color-grey-4;

$color-text-titles: #1e0028;

$color-transparent: transparent;

$font-size-smaller: 0.75rem;
$font-size-small: 0.9375rem;
$font-size: 1rem;
$font-size-big: 1.125rem;
$font-size-bigger: 1.25rem;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$link: $color-accent;

$box-shadow-small: 0px 0px 15px rgba(0, 0, 0, 0.1);
$box-shadow: 0px 8px 16px rgba(30, 0, 40, 0.08) !important;
$box-shadow-large: 0px 16px 48px rgba(30, 0, 40, 0.1);

$box-shadow-hover: 0 0 20px 0 rgba(0, 0, 0, 0.05);

$border-radius-min: 0.3rem;
$border-radius: 0.625rem;
$border-radius-cta: 2.5rem;

$trans: 0.2s all ease-in-out;
$trans-2: 0.3s all ease-in-out;
$trans-3: 0.6s all ease-in-out;
$trans-4: 0.8s all ease-in-out;
$trans-5: 1s all ease-in-out;

$header-1: 3rem;
$header-1-res: 2.5rem;
$header-2: 2.1875rem;
$header-2-res: 1.875rem;
$header-3: 1.5rem;
$header-3-res: 1.25rem;
$header-4: 1.25rem;
$header-5: 1rem;
$header-6: 0.87rem;

$display-extra-large: 8.375rem;
$display-1: 96px;
$display-2: 4.35rem;
$display-3: 3.45rem;
$display-4: 2.18rem;

$space-0: 0px;
$space-05: 0.5rem;
$space-01: 1rem;
$space-1: 1.5rem;
$space-02: 2rem;
$space-2: 2.5rem;
$space-3: 4rem;
$space-4: 5rem;
$space-5: 7.5rem;
$space-6: 13.4375rem;

$typo-primary: "PT Serif", serif;
$typo-secondary: "Prata", serif;

$saturate: saturate(56%);

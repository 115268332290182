.h-proyectos {
  padding: $space-5 0 $space-3 0;
  margin-bottom: 40rem;
  &__grid {
    gap: $space-6 $space-2;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: $space-4;
      width: calc(100vw - 10%);
      margin-right: 5%;
      margin-left: 5%;
    }
  }
}

.p-proyectos {
  &__grid {
    gap: $space-6 $space-2;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: $space-4;
      width: calc(100vw - 10%);
      margin-right: 5%;
      margin-left: 5%;
    }
  }
}

.thumb-proyecto {
  flex: 1 1 43vw;
  padding-left: 7vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $space-01;
  color: $color-text-titles;
  transition: $trans;
  &:hover {
    color: $color-text-titles;
    .thumb-proyecto__image img {
      scale: 1.1;
      transition: $trans-2;
    }
  }
  @media screen and (max-width: 767px) {
    flex: 1 1 100%;
    padding-left: 0;
    gap: 0;
  }

  &:nth-last-child(1) {
    // display: none;
    max-width: 50vw;
    @media screen and (max-width: 767px) {
      max-width: 100vw;
    }
  }

  &__image {
    aspect-ratio: 0.843;
    width: 100%;
    height: auto;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-01;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $trans-2;
    }
  }

  &__title {
    font-family: $typo-secondary;
    font-size: $header-2;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
    }
    @media screen and (max-width: 480px) {
      font-size: $header-3;
    }
  }

  &__link {
    font-size: $header-3;
    @media screen and (max-width: 767px) {
      font-size: $header-3-res;
    }
    @media screen and (max-width: 480px) {
      font-size: $header-4;
    }
  }
}

.s-proyecto {
  padding-bottom: $space-6;
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-4;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-3;
    }
  }

  &__datos {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
      order: 2;
    }
  }

  &__title {
    font-size: $header-2;
  }

  &__summary {
    margin-bottom: $space-2;
  }

  &__info {
    width: 100%;
  }

  &__info-item {
    display: flex;
    justify-content: flex-start;
    padding: $space-05 0;
    border-bottom: 1px solid $color-grey-3;
    color: $color-text-titles;
    &:nth-child(1) {
      border-top: 1px solid $color-grey-3;
    }
  }

  &__info-title {
  }

  &__info-description {
    margin-left: 0.25rem;
  }

  &__picture {
    align-self: center;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    @media screen and (max-width: 991px) {
      order: 1;
    }
  }
}

.p-galeria {
  padding-bottom: $space-6;
  &__slider {
  }

  &__img {
    width: 100%;
    height: auto;
    padding: 0 0.5rem;
  }
}

.slick-dots {
  button::before {
    font-size: 12px !important;
  }
}

.navegador-proyectos {
  display: grid;
  grid-template-columns: 15% 70% 15%;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "thumb-prev content-next thumb-next"
    "thumb-prev content-prev thumb-next";
  border-top: 1px solid $color-text-titles;
  border-bottom: 1px solid $color-text-titles;

  &__item {
  }

  &__content {
    width: 100%;
    justify-self: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    a {
      display: flex;
      align-items: center;
      color: $color-text-titles;
    }
    span {
      color: $color-grey-4;
    }
    &--next {
      font-size: $header-2;
      @media screen and (max-width: 991px) {
        font-size: $header-3;
      }
      @media screen and (max-width: 767px) {
        font-size: $header-3-res;
      }
      grid-area: content-next;
      a {
        align-self: flex-end;
      }
    }
    &--prev {
      grid-area: content-prev;
      a {
        align-self: flex-start;
      }
    }
  }

  &__thumbnail {
    display: block;
    margin: 0;
    width: 100%;
    img {
      width: 100%;
      height: 180px;
      object-fit: cover;
    }
    &--next {
      grid-area: thumb-next;
      justify-self: flex-end;
    }
    &--prev {
      grid-area: thumb-prev;
    }
  }
}
